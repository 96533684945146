<template>
  <Form
    ref="formValidate"
    :model="model"
    :rules="rule"
    label-position="top"
    class="iv-custom-form"
  >
    <Row :gutter="48">
      <Col
        v-for="(item, index) in layoutList"
        :key="index"
        :span="getCol(item)"
        class="iv-m-t-24"
        :style="{height: computedHeight(item.type)}"
      >
      <Row
        :gutter="48"
        v-if="Array.isArray(item)"
      >
        <Col
          v-for="(val, $index) in item"
          :key="$index"
          :class="{'iv-m-t-24': $index > 1}"
          span="12"
          :style="{height: computedHeight(val.type)}"
        >
        <Child
          :model="model"
          :index="$index"
          :item="val"
          :inReadOnly="inReadOnly"
          @on-upload-success="uploadSuccess"
          @on-input-clear="onClearFile"
          @on-change-all="handChangeAll"
          @on-change-tree="handlerChangeTree"
          @on-blur="handleInputBlur"
          @on-change="handleChange"
          @on-change-diff="handleChangeDiff"
          @on-child-checkbox-change="childCheck"
        >
        </Child>
        </Col>
      </Row>
      <Child
        v-else
        :model="model"
        :item="item"
        :index="index"
        :inReadOnly="inReadOnly"
        @on-input-clear="onClearFile"
        @on-upload-success="uploadSuccess"
        @on-change-all="handChangeAll"
        @on-change-tree="handlerChangeTree"
        @on-blur="handleInputBlur"
        @on-change="handleChange"
        @on-change-diff="handleChangeDiff"
        @on-child-checkbox-change="childCheck"
      >
      </Child>
      </Col>
    </Row>
  </Form>
</template>

<script>
import Child from './child'
import { cloneDeep } from 'lodash'
import FileUpload from '@/components/upload'
import IconFont from '@/components/iconfont'
import ImgUpload from '@/components/img-upload'

class Stack {
  stack = []
  push(index, idx, item) {
    this.stack[index] = this.stack[index] || {}
    this.stack[index].index = idx
    this.stack[index].list = this.stack[index].list || []
    this.stack[index].list.push(item)
  }
  get() {
    return this.stack
  }
}

export default {
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rule: {},
      layoutList: []
    }
  },
  computed: {
    inReadOnly() {
      return this.readOnly
    }
  },
  watch: {
    list() {
      this.layoutList = this.splitList(this.list || [])
    }
  },
  created() {
    this.layoutList = this.splitList(this.list || [])
  },
  provide() {
    return {
      expandParent: this.expandParent
    }
  },
  methods: {
    expandParent(res, data, dwShortName, list) {
      if (res === 'term') {
        delete this.model[dwShortName]
        this.$set(this.model, dwShortName, data)
      }
    },
    computedHeight(type) {
      if (undefined === type) {
        return 'auto'
      }
      return `${(type == 'textarea' || type == 'photo' || type == 'FileUpload' || type == 'fileUpload' || type == 'MultipleCheckbox' || type == 'photoCut' || type == 'fileUploadOne') ? 'auto' : '64px'}`
    },
    onClearFile() {
      if (arguments[2].length == 0) {
        this.$refs['formValidate'].validateField(arguments[3])
      }
      delete this.model[arguments[3]]
      this.$set(this.model, arguments[3], arguments[2])
    },
    uploadSuccess() {
      const config = arguments[3] || arguments[2] || arguments[1] 
      if (config.props.multiple) {
        if (!this.model[arguments[1]]) this.model[arguments[1]] = []
        this.model[arguments[1]].push(arguments[2])
      }
      console.log(arguments);
      //这里为了图片ID的获取扩展一下字段coverFileId
      if (arguments[1] == 'photo' || arguments[1].type == "photoCut") {
        console.log(arguments[0].response.data.fileId, 'arguments[0].response.data.fileId')
        console.log(arguments[1],'arguments[1].key')
        this.model[arguments[1]] = arguments[0].response.data.fileId
        // this.$refs['formValidate'].validateField(arguments[1].key)
      }
      else{
        //  this.$refs['formValidate'].validateField(arguments[1])
      }    
      this.$Message.success('上传成功')
      this.$emit('on-upload-success', ...arguments)
    },
    //选人事件  全部数据
    handChangeAll(arr) {
      this.$emit('on-change-all', ...arguments)
    },
    childCheck(val, key) {
      this.$emit('on-child-checkbox-change', val, key)
    },
    //radio  Select等的change事件
    handleChange() {
      this.$emit('on-change', ...arguments)
    },
    handleChangeDiff() {
      this.$emit('on-change-diff', ...arguments)
    },
    //树选择等的change事件
    handlerChangeTree(data, keyValue) {
      this.$emit('on-change-tree', ...arguments)
      if (typeof keyValue === 'string' && keyValue.trim() !== '') {
        this.$refs['formValidate'].validateField(keyValue);
      }
    },
    handleInputBlur(item, data) {
      this.$emit('on-blur', item, data)
    },
    getCol(item) {
      if (item.colSpan) {
        return item.colSpan - 0
      }
      if (Array.isArray(item)) {
        return 16
      }
      return 8
    },
    //校验
    validate(resolve) {
      this.$refs['formValidate'].validate((validate) => {
        resolve(validate)
      })
    },
    //返回promise
    validateify() {
      return this.$refs['formValidate'].validate()
    },
    //移除校验
    resetFields() {
      this.$refs['formValidate'].resetFields()
    },
    //图片占地大 要特殊处理
    splitList(sourceList) {
      let list = cloneDeep(sourceList)
      this.initList(list)
      const types = list.map(item => item.type)
      if (!types.includes('photo')) return list
      let current = 0
      let res = new Stack()
      list.forEach((item, index) => {
        if (item.type === 'photo') {
          //为了拓展业务需求,这里增加一个逻辑判断,当有DoNothing不做处理属性传进来时,那么就不对图片做特殊处理了
          if(item.DoNothing)return
          let lines = (index + 1) % 3
          switch (lines) {
            case 1:
              list[index + 1] && res.push(current, index + 1, list[index + 1])
              list[index + 2] && res.push(current, index + 1, list[index + 2])
              list[index + 3] && res.push(current, index + 1, list[index + 3])
              list[index + 4] && res.push(current, index + 1, list[index + 4])
              break
            case 2:
              list[index - 2] && res.push(current, index - 2, list[index - 2])
              list[index - 1] && res.push(current, index - 2, list[index - 1])
              current++
              list[index + 1] && res.push(current, index + 1, list[index + 1])
              list[index + 2] && res.push(current, index + 1, list[index + 2])
              break
            case 0:
              list[index - 2] && res.push(current, index - 2, list[index - 2])
              list[index - 1] && res.push(current, index - 2, list[index - 1])
              list[index + 1] && res.push(current, index - 2, list[index + 1])
              list[index + 2] && res.push(current, index - 2, list[index + 2])
              break
          }
          current++
        }
      })
      const result = res.get()
      result.forEach(item => {
        item.list.forEach(val => {
          const index = list.findIndex(v => v.title === val.title)
          list.splice(index, 1)
        })
        list.splice(item.index, 0, item.list)
      })
      return list
    },
    //初始化列表
    initList(list) {
      const changeTypes = ['Select', 'DatePicker', 'Checkbox', 'Radio', 'selectInputMember', 'fileUpload', 'fileUploadOne']
      const ArrayTypes = ['Checkbox', 'selectInputMember', 'fileUpload', 'fileUploadOne']
      list.forEach(item => {
        if (item.rules && item.rules[0]) {
          let type = 'blur'
          let format = 'string'
          if (changeTypes.includes(item.type)) {
            type = 'change'
          }
          if (ArrayTypes.includes(item.type)) {
            format = 'array'
          }
          if (item.type === 'Number' || item.type === 'InputNumber') {
            format = 'number'
          }
          if (item.type === 'DatePicker') {
            format = 'date'
          }
          //自定义项目开始日期过滤
          const validateProjStartDate = (rule, value, callback) => {
            if (value == '') {
              callback(new Error('此项必填'))
            } else if (value != '' && this.model.projEndDate != '' && value.getTime() > this.model.projEndDate.getTime()) {
              callback(new Error('项目开始日期不能晚于项目结束时间'))
            } else {
              callback()
            }
          }
          //自定义项目结束日期过滤
          const validDteprojEndDate = (rule, value, callback) => {
            if (value == '') {
              callback(new Error('此项必填'))
            } else if (value != '' && this.model.projStartDate != '' && value.getTime() < this.model.projStartDate.getTime()) {
              callback(new Error('项目结束日期不能早于项目开始时间'))
            } else {
              callback()
            }
          }
          //自定义规则  直接拿
          this.rule[item.key] = []
          item.rules.forEach(val => {
            if (Object.prototype.toString.call(val) === '[object Object]') {
              this.rule[item.key].push(val)
            } else if (item.type === 'selectInputMember' && val === 'required') {
              this.rule[item.key].push({ required: true, message: '此项必填' })
            } else if (item.key == 'projStartDate') {
              this.rule[item.key].push({ required: true, type: format, trigger: type, validator: validateProjStartDate })
            } else if (item.key == 'projEndDate') {
              this.rule[item.key].push({ required: true, type: format, trigger: type, validator: validDteprojEndDate })
            } else {
              this.rule[item.key].push({ required: true, type: format, message: '此项必填', trigger: type })
            }
          })
        }
      })
    }
  },
  components: {
    Child,
    IconFont,
    ImgUpload,
    FileUpload
  }
}
</script>

<style lang="scss">
.iv-custom-form {
  .ivu-form-item-label {
    margin-bottom: 0 !important;
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.65);
  }
  .ivu-form-item-content {
    textarea {
      resize: none;
    }
  }
  .ivu-radio-group {
    left: 0;
    top: 0;
    .ivu-radio-checked .ivu-radio-inner {
      border-color: #45bc7d !important;
      &:after {
        background: #45bc7d !important;
      }
    }
  }
  .iv-custom-form-extra {
    right: 0;
    top: -2px;
    color: #fa2900;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
  .iv-visable {
    visibility: hidden;
  }
}
.ivu-input {
  font-size: 14px !important;
}
.ivu-select {
  .ivu-select-selected-value,
  .ivu-select-placeholder {
    font-size: 14px !important;
  }
}
</style>
