<template>
  <div class="iv-imgupload-wrapper">
    <div v-if="readOnly" class="img-wrap-content">
      <img :src="previewSrc ? previewSrc : require('@/assets/images/user/header-3.png')" alt="">
    </div>
    <div v-else>
      <FileUpload
        :style="styles"
        class="iv-img-upload iv-pointer iv-text-center"
        v-model="uploadFiles"
        :accept="accept"
        :post-action="url"
        :data="uploadData"
        :headers="headers"
        name="multipartFiles"
        v-bind="$attrs"
        @input-file="inputFile"
        ref="fileUpload">
        <div class="iv-mask iv-text-center iv-fs-24">
          <Icon class="iv-white iv-fs-24" type="android-camera"></Icon>
          <p class="iv-white iv-fs-16 iv-hover-color">点击上传</p>
        </div>
        <div v-if="hasDefaultImg" class="iv-image-view" :style="{...styles, 'background-image': `url(${previewSrc ? previewSrc : require('@/assets/images/user/header-3.png')})`}">
        </div>
        <div v-else class="upload-cnt">
          <span>+</span>
          <span>上传图片</span>
        </div>
      </FileUpload>
    </div>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component'
import IconFont from '@/components/iconfont'
// import { prefixPath } from '@/config'
export default {
  props: {
    url: {
      type: String,
      default: 'api-uum/file/upload'
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Object,
      default () {
        return {
          groupId: 'tupian',
          uploadUser: '123'
        }
      }
    },
    src: {
      type: String,
      default: ''
    },
    autoActive: {
      type: Boolean,
      default: true
    },
    option: {
      type: Object,
      default () {
        return {}
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    keyValue: {
      type: String,
      default: ''
    },
    identification:{
      type:String,
      default: ''
    }
  },
  data () {
    return {
      uploadFiles: [],
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      uploadData: this.data,
      previewSrc: ''
    }
  },
  inject: {
    hasDefaultImg: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  watch: {
    src: {
      //动态表单特殊处理 时间紧迫 没时间追查问题  暂时这样处理   err：props传递过来数据候  组件会重置一次
      handler (newVal, oldVal) {
        if (newVal === '' && oldVal) {
             this.previewSrc = oldVal
           } 
        else if(newVal === '' && oldVal==undefined){
           if(this.identification =='xiehui'){
           this.previewSrc = require('@/assets/images/user/default-pic2.png')
          }
        }
        else {
           this.previewSrc = newVal
        }
      },
      immediate: true
    },
    data (val) {
      this.uploadData = val
    },
    // 上传后就表示有图片了
    previewSrc () {
      this.hasDefaultImg = true
    }
  },
  components: {
    FileUpload,
    IconFont
  },
  methods: {
    clear (item) {
      this.$refs.fileUpload.clear()
    },
    inputFile (newFile, oldFile) {
      this.$emit('on-input-file', newFile)
      if (newFile && oldFile) {
        if (newFile.success && !oldFile.success) {
          if (newFile.response.code == '0' || newFile.response.status == 'success') {
            this.$emit('on-upload-success', newFile, this.keyValue)
          } else {
            this.$emit('on-upload-error', newFile, this.keyValue)
          }
          this.clear()
        }
      }
      if (Boolean(newFile) !== Boolean(oldFile) || newFile.error !== oldFile.error) {
        if (!this.$refs.fileUpload.active) {
          let isImg = /(png|jpe?g|gif)\s*$/.test(newFile.type)
          let name = (newFile && newFile.name) || (oldFile && oldFile.name)
          let suffix = name.slice(name.lastIndexOf('.') + 1)
          let isAccept = this.accept.indexOf(suffix) > -1 || this.accept === '*'
          if (isAccept || isImg) {
            this.$refs.fileUpload.active = this.autoActive
            try {
              this.previewSrc = URL.createObjectURL(newFile.file)
            } catch (e) {
              this.previewSrc = ''
            }
            return
          }
          this.$Message.error(`文件类型不匹配`)
        }
      }
    },
    startUpload () {
      this.$refs.fileUpload.active = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'variable/variable.scss';
.iv-iconfont {
  font-size: 24px;
}
.iv-white {
  color: #fff;
}
.iv-imgupload-wrapper {
  height: 120px;
  .img-wrap-content {
    height: 100%;
    text-align: center;
    img {
      height: 100%;
    }
  }
  .iv-image-view {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .iv-img-upload {
    height: 120px;
    line-height: 120px;
    // background: #ccc;
    border-radius: 4px;
    width: 100%;
    &:hover {
      .iv-mask {
        transform: translateY(0);
      }
    }
    .iv-mask {
      background: rgba(0, 0, 0, .3);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: translateY(110%);
      transition: transform .3s linear 0s;
      display: table;
      i {
        display: table-cell;
        vertical-align: middle;
      }
      p {
        display: table-row;
      }
    }
    img {
      height: 100%;
    }
  }
}
.upload-cnt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.02);
  color: #999;
  height: 120px;
  border: 2px dashed #dddee1;
  border-radius: 16px;
  span {
    font-size: 20px;
    height: auto;
    line-height: 1;
    & + span {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
