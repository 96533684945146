<template>
  <div class="iv-remote-wrapper">
    <Select
        :placeholder="disabled ? '' : placeholder"
        :disabled="disabled"
        v-model="inValue"
        :multiple="multiple"
        :filterable="filterable"
        ref="Select"
        v-bind="$attrs"
        @on-change="onChang"
        :loading="loading">
        <Option
          v-for="(option, index) in options"
          :value="option[map.value]"
          :key="index">{{option[map.title]}}</Option>
    </Select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default () {
        return {
          title: 'title',
          value: 'value'
        }
      }
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    method: {
      type: String,
      default: 'GET'
    },
    optionList: {
      type: Array,
      default () {
        return []
      }
    },
    filterable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    }
  },
  data () {
    return {
      loading: false,
      options: this.optionList,
      inValue: this.value
    }
  },
  created () {
    this.remoteMethod()
  },
  inject: {
    onSearchChange: {
      default () {
        /*eslint-disable*/
        return new Function
      }
    }
  },
  watch: {
    value (val) {
      this.inValue = val
    },
    inValue (val) {
      this.$emit('input', val)
    },
    optionList (val) {
      this.options = val
    }
  },
  methods: {
    remoteMethod (query) {
      if (!this.url) return
      this.$ajax({
        url: this.url,
        method: this.method,
        data: this.params
      }).then(({data}) => {
        this.options = data.data
      })
    },
    onChang (val) {
      this.$emit('input', val)
      this.$emit('on-change', this.options, val)
    }
  }
}
</script>
<style lang="scss">
.iv-remote-wrapper{
  .ivu-select-input{
    height: 32px !important;
    line-height: 32px !important;
  }
}
</style>
