<template>
  <div>
    <div v-if="uploadFiles.length && isShowFileList">
      <ul>
        <li v-for="(item, index) in uploadFiles" :key="index" class="iv-m-b-12">
          <span class="iv-pointer iv-hover-color" @click="pictureSize(item, 'upload')">{{item.name}}</span>
          <IconFont v-if="!readOnly" class="iv-pull-right iv-pointer iv-hover-color" type="close" @click="delUploadList(item, index)"></IconFont>
        </li>
      </ul>
    </div>
    <FileUpload
      v-if="!readOnly"
      class="iv-ins-upload iv-pointer"
      v-model="uploadFiles"
      :accept="accept"
      :post-action="url"
      :data="uploadData"
      :headers="headers"
      v-bind="$attrs"
      name="multipartFiles"
      :multiple="multiple"
      @input-file="inputFile"
      ref="fileUpload">
      <span
        v-if="!isText"
        type="ghost"
        class="ivu-btn iv-fc-65 iv-pointer">
        <IconFont
          class="iv-iconfont"
          :type="icon"></IconFont>
        {{label}}
      </span>
      <span :class="dynamicClass" v-if="isText">{{label}}</span>
    </FileUpload>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import IconFont from '@/components/iconfont'
import {prefixPath} from '@/config'
import {timeFormat, isIE9} from '@/utils'
import { chunkUploadMixin } from '@/mixins/file-upload'

const MINSIZE = 10485760
export default {
  mixins: [chunkUploadMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: prefixPath + 'file/upload'
      // default: prefixPath + 'zuul/api-file/workbench/' + (isIE9() ? 'ie/file/saveFileHtml' : 'file') + '?_csrf=' + sessionStorage.getItem('token')
    },
    accept: {
      type: String,
      default: '*'
    },
    icon: {
      type: String,
      default: 'cloud-upload'
    },
    label: {
      type: String,
      default: '上传'
    },
    ghost: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: false
    },
    isMuleiple: {
      type: Boolean,
      default: false
    },
    titleText: {
      type: String,
      default: '点击这里'
    },
    formatText: {
      type: [String, Array],
      default: '文件格式支持MP4'
    },
    data: {
      type: Object,
      default () {
        return {
          override: true,
          path: '/cw',
          groupId: '',
          currentAuthScopeId: sessionStorage.getItem('listOrgId')
        }
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    getUploadFiles: {
      type: Array,
      default () {
        return []
      }
    },
    preview: {
      type: Object,
      default () {
        return {}
      }
    },
    size: {
      type: Number
    },
    timeout: {
      type: Number
    },
    autoClear: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    isText: { //是否纯文字  不用button展示
      type: Boolean,
      default: false
    },
    dynamicClass: {
      type: String,
      default: ''
    },
    isShowFileList: { //是否展示上传列表
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      uploadFiles: this.multiple == true ? this.getUploadFiles : this.preview.type ? [this.preview] : [],
      headers: {
        'X-XSRF-TOKEN': sessionStorage.getItem('token'),
        'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      fileTypeSrc: '',
      showProgess: this.preview || !this.autoUpload ? '' : true,
      showWrongClass: false,
      uploadPercent: 0,
      progessStatus: 'normal',
      acceptType: '',
      extensions: '',
      fileTypeList: []
    }
  },
  created () {
    if (this.multiple) {
      this.uploadFiles = this.getUploadFiles
    } else {
      this.uploadFiles = this.preview.type ? [this.preview] : []
    }
    this.normalizeType()
    this.fileChunk = {
      action: prefixPath + 'zuul/api-file/workbench/file/temp/chunk/vue',
      headers: {
        'X-XSRF-TOKEN': sessionStorage.getItem('token'),
        'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      minSize: MINSIZE,
      maxActive: 3,
      maxRetries: 5,
      startBody: {
        override: true,
        path: '/cw'
      },
      uploadBody: {
        override: true,
        path: '/cw'
      },
      finishBody: {
        override: true,
        path: '/cw'
      }
    }
  },
  computed: {
    uploadData () {
      return Object.assign({
        override: true,
        path: '/cw',
        currentAuthScopeId: sessionStorage.getItem('listOrgId')
      }, this.data)
    }
  },
  components: {
    FileUpload,
    IconFont
  },
  watch: {
    // uploadFiles: {
    //   handler (newVal) {
    //   }
    // },
    preview: {
      handler (newVal) {
        let flag = !!newVal.type
        this.uploadFiles = flag ? [newVal] : []
        this.showProgess = this.autoUpload ? !flag : false
      },
      immediate: true
    },
    getUploadFiles: {
      handler (newVal) {
        if (newVal != null) {
          newVal.map(item => {
            item.type = item.fileExt
            item.newTime = timeFormat(item.uploadTime)
            item.name = item.fileName
            item.imgType = this.getImgType(item.name)
            this.$set(item, 'fileTypeSrc', this.getType2Image(item.imgType))
          })
          this.uploadFiles = newVal
        }
      },
      deep: true
    }
  },
  methods: {
    getPreviewSrc () {
      if (!this.preview || !this.preview.type) return ''
      let type = this.getImgType(this.preview.type)
      this.$set(this.preview, 'src', this.getType2Image(type))
      return this.preview.src || ''
    },
    getImgType (item) {
      let a = item.substring(item.lastIndexOf('.') + 1)
      let b = ''
      if (a == 'xlsx' || a === 'xls') {
        b = 'xls'
      } else if (a == 'jpg' || a == 'png' || a == 'gif' || a == 'jpeg') {
        b = 'img'
      } else if (a == 'docx' || a == 'doc') {
        b = 'doc'
      } else if (a == 'txt') {
        b = 'txt'
      } else if (a == 'pdf') {
        b = 'pdf'
      } else if (a == 'mp4' || a == 'MP4') {
        b = 'mp4'
      } else if (a == 'mp3') {
        b = 'mp4'
      } else if (a == 'zip') {
        b = 'zip'
      } else {
        b = 'xml'
      }
      return b
    },
    reUpload (item) {
      this.progessStatus = 'normal'
      this.showWrongClass = false
      this.$refs.fileUpload.remove(item)
    },
    delUploadList (item, index) {
      this.$confirmModal.warning({
        title: this.tips?.reqText?.delTitle || '提示',
        content: this.tips?.reqText?.delContent || '是否确认删除？'
      }).then(res => {
        const fileId = item?.response?.data?.fileId || item.fileId
        if (fileId) {
          this.deleteFileRequest(fileId).then(({data}) => {
            this.componetDel(item, index, res)
          })
        } else {
          this.componetDel(item, index, res)
        }
      })
    },
    //组件删除 封装
    componetDel (item, index, res) {
      for (var i = this.uploadFiles.length - 1; i >= 0; i--) {
        if (this.multiple) {
          //解决删除，文件名相同时，删除多个问题
          if ((item.id != undefined && item.id === this.uploadFiles[i].id) || (item.id == undefined && item.fileId === this.uploadFiles[i].fileId)) {
            this.uploadFiles.splice(i, 1)
          }
        } else {
          if (item.name === this.uploadFiles[i].name) {
            this.uploadFiles.splice(i, 1)
          }
        }
      }
      if (this.uploadFiles.length == 0) {
        this.$refs.fileUpload.clear()
      }
      this.fileTypeList.splice(index, 1)
      this.$emit('on-input-clear', this.$refs.fileUpload, item, this.uploadFiles)
      res()
    },
    //删除附件 接口
    deleteFileRequest (fileId) {
      return this.$ajax({
        url: 'file/deleteFiles',
        method: 'delete',
        data: {
          fileIds: [fileId]
        }
      })
    },
    isEmptyObject (obj) {
      try {
        for (let i in obj) {
          if (obj.hasOwnProperty(i)) return true
        }
      } catch (err) {
        return false
      }
      return false
    },
    sendError (newFile, oldFile) {
      let error = newFile && newFile.error
      //错误信息提示
      if (error) {
        switch (error) {
          case 'size':
            this.$Message.error('文件过大，请重新上传')
            break
          case 'extension':
            this.$Message.error('文件类型错误，请重新上传')
            break
          case 'timeout':
            this.$Message.error('文件上传超时，请重新上传')
            break
        }
        this.uploadError(newFile)
        this.autoClear && this.$refs.fileUpload.clear()
        return true
      }
      if (newFile) {
        if (newFile.size > this.size) {
          this.$Message.error('文件上传失败，附件过大，请按规定大小上传文件')
          this.$refs.fileUpload.active = false
          this.uploadError(newFile)
          return true
        }
      }
      if (newFile && oldFile) {
        if (newFile.success && !oldFile.success) {
          if (isIE9()) {
            newFile.response.data = newFile.response
            newFile.size = newFile.response.size
          }
          if (newFile.response.code == '0' || newFile.response.status == 'success' || isIE9()) {
            // 源于file-upload mixins
            this.checkChunkUpload(newFile)
          } else {
            this.uploadError(newFile)
            return true
          }
        }
      }
    },
    sendClear () {
      if (this.clear) {
        //清空
        Object.keys(this.preview).forEach(key => {
          delete this.preview[key]
        })
        this.clear = false
        return true
      }
    },
    // 初始化上传状态
    sendInitUpload (newFile, oldFile) {
      if (Boolean(newFile) !== Boolean(oldFile) || newFile.error !== oldFile.error) {
        if (!this.$refs.fileUpload.active) {
          let name = (newFile && newFile.name) || (oldFile && oldFile.name)
          let suffix = name.slice(name.lastIndexOf('.') + 1)
          let isAccept = this.extensions.indexOf(suffix) > -1
          if (isAccept || this.accept === '*') {
            this.uploads = newFile
            this.$refs.fileUpload.active = this.autoUpload
            this.uploadFiles.forEach((item, index) => {
              item.newTime = timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
              item.imgType = this.getImgType(item.name)
              this.$set(this.fileTypeList, index, this.getType2Image(item.imgType))
            })
          }
        }
      }
      //更新上传进度
      if (newFile) {
        this.updatePercent()
        this.progessStatus = 'normal'
        this.showWrongClass = false
        this.showProgess = true
      }
    },
    inputFile (newFile, oldFile) {
      this.$emit('on-input-file', newFile, oldFile)
      if (this.sendError(newFile, oldFile)) return
      if (this.sendClear()) return
      if (this.isEmptyObject(this.preview)) return
      this.sendInitUpload(newFile, oldFile)
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        //如果是chunk上传，我们要自定义上传进度
        if (isIE9() || newFile.size < MINSIZE) return
        const updateProgress = () => {
          this.updatePercent()
          //避免过度执行此函数，使用macrotask
          setTimeout(() => {
            updateProgress()
          }, 16.6)
        }
        this.$nextTick(updateProgress)
      }
    },
    updatePercent () {
      if (this.uploadPercent >= 100) return
      let progress = (this.$refs.fileUpload.files[0] && this.$refs.fileUpload.files[0].progress) || 0
      this.uploadPercent = ~~progress
    },
    uploadSuccess (newFile) {
      if (newFile.response.data && newFile.response.data.code == -1) {
        this.$emit('on-upload-error', newFile)
      } else {
        this.$emit('on-upload-success', newFile)
      }
    },
    uploadError (newFile) {
      this.progessStatus = 'wrong'
      this.showWrongClass = true
      this.$emit('on-upload-error', newFile)
    },
    normalizeType () {
      if (this.accept === '*') return
      const types = {
        'image': 'bmp, jpg, png, gif, svg, webp, bmp, jpe',
        'video': 'mp4, flv, webm, f4v, mkv, mpg, avi, wmv, mov, swf, ogg',
        'word': 'doc, docx, xls, xlsx, pdf, txt, md, xml, wps',
        'attachment': 'zip, rar, taz, gz'
      }
      let accept = this.accept.toLocaleLowerCase()
      // if: accept = 'image, video, .doc, jpg, mp4'
      accept = accept.split(',').reduce((result, type) => {
        // if: type === ' .jpg'
        type = (type = type.trim()).charAt(0) === '.' ? type.slice(1) : type
        // if: type === 'image, video, jpg'
        result.push(
          ...(types[type]
            ? types[type].split(',').map(t => t.trim())
            : [type])
        )
        return result
      }, [])
      /*
        * acceptType=".doc,.jpg,.xml" => MIME类型
        * extensions="jpg,gif,png" => vue-upload-component
        * */
      this.acceptType = `.${accept.join(',.')}`
      this.extensions = accept.join(',')
    },
    // 换算图片格式的大小
    pictureSize (size, data) {
      if (data != undefined && data == 'upload') {
        if (size.fileId == undefined) {
          size.fileId = size.response.data.fileId
        }
        let url = `${prefixPath}file/downloadFile?fileId=${size.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      }
      if (isNaN(size)) { // 判断是不是一个数
        return ''
      }
      let uniTsize = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] // 保存文件的格式
      let exp = Math.floor(Math.log(size) / Math.log(2)) // 对数函数
      if (exp < 1) {
        exp = 0
      }
      let i = Math.floor(exp / 10)
      size = size / Math.pow(2, 10 * i)
      if (size.toString().length > size.toFixed(2).toString().length) {
        size = size.toFixed(2)
      }
      return size + ' ' + uniTsize[i]
    },
    // 开始上传
    startUpload () {
      this.showProgess = true
      this.progessStatus = 'normal'
      this.showWrongClass = false
      this.uploadPercent = 0
      this.$refs.fileUpload.active = true
    },
    getType2Image (type) {
      // return require(`@/static/images/${type}@2x.png`)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'variable/variable.scss';
.iv-iconfont {
  font-size: 14px;
}
.iv-white {
  color: #fff;
}
</style>
