<!--管理用户管理-->
<template>
  <div class="iv-pos-r iv-selecttree-wrapper">
    <!-- <div>
      <Select :transfer="params.transfer" :disabled="disabled" v-model="checkedList" multiple :style="{width: disabled ? '' : 'calc(100% - 48px)'}">
        <Option v-for="(item, ind) in checkedOptions" :value="item.value" :key="ind">{{item.label}}</Option>
      </Select>
      <span v-show="!disabled" class="iv-append iv-pointer" @click="showModal">选择</span>
    </div> -->
    <Input v-if="showInput" readonly v-model="handlerValue" :disabled="disabled">
      <span slot="append" class="iv-pointer" @click="showModal" v-show="!disabled">选择</span>
    </Input>
    <Modal :title="newTitle" v-model="showOrganModal" width="768">
      <ModalLayout>
        <h3 class="iv-m-b-12 iv-fs-16">操作对象：点击弹出层的操作源名称，如xxx党支部
          <span class="iv-fs-12 iv-m-b-16" style="color: #f00">(*请展开所要选择的节点之后再选择党组织机构！)</span>
        </h3>
        <!-- <Row :gutter="8" class-name="iv-m-l-0 iv-m-r-0 iv-m-b-12 iv-search-organ iv-p-t-8 iv-p-b-8 iv-p-l-4 iv-p-r-4">
          <Col :span="3">
            <span style="line-height: 32px;">快速选择</span>
          </Col>
          <Col :span="4">
            <Select v-model="checkLevel" clearable>
              <Option v-for="item in levelList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </Col>
          <Col :span="9">
            <Select multiple v-model="checkType">
              <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </Col>
          <Col :span="3">
            <Button type="bacth" size="small" long noIcon @click="quickSearch">快速重选</Button>
          </Col>
          <Col :span="5">
            <span style="line-height: 32px;">注意：会覆盖已选择</span>
          </Col>
        </Row> -->
        <Row class-name="iv-content">
          <Col :span="12" class-name="iv-p-8 iv-content-left">
            <!-- <IconTree
              ref="tree"
              :data="dataTree"
              :checkStrictly="true"
              :showCheckBox="true"
              :showSpread="false"
              :reloadTable="false"
              :showIcon="false"
              @on-check-change="onSelectChangeTree"/> -->
            <LazyTree
              async
              ref="tree"
              showCheckbox
              :changeTree="false"
              :checkStrictly="isContain"
              :treeParams="treeParamsMap"
              :treeMap="treeMap"
              :treeType="treeType"
              :isRootDisabled="isRootDisabled"
              :treeStyle="{'overflow-y': 'scroll', 'max-height': '210px'}"
              @on-check-change="onSelectChangeTree"
              @on-select-change="onSelectChange" />
            <div class="iv-is-contain iv-p-l-24">
              <span class="iv-m-r-8">选中时包含下级</span>
              <i-switch v-model="isContain">
                <span slot="open">开</span>
                <span slot="close">关</span>
              </i-switch>
            </div>
          </Col>
          <Col :span="12" class-name="iv-p-8 iv-content-right">
            <ul>
              <li v-for="item in orgList" :key="item.value" class="iv-m-b-4">
                <Tag :closable="!(treeParamsMap.disabledSelectIds && treeParamsMap.disabledSelectIds.includes(item.value))"  @on-close="onTagClose(item.value)">{{item.label}}</Tag>
              </li>
            </ul>
          </Col>
        </Row>
      </ModalLayout>
      <div slot="footer">
        <Button type="sure" noIcon size="small" @click="onOK">确认</Button>
        <Button type="reset" noIcon size="small" @click="resetTree">重置</Button>
        <Button type="cancel" noIcon size="small" @click="cancleTree">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {throttle} from 'lodash'
import Modal from '@/components/modal'
import ModalLayout from '@/components/modal/layout'
// import IconTree from 'components/icon-tree'
import tagGroup from '@/components/tag-group'
import LazyTree from '@/components/lazy-tree'

export default {
  props: {
    props: {
      type: Object,
      default () {
        return {}
      }
    },
    isRootDisabled: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    userAsync: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    attrKey: {
      type: String,
      default: ''
    },
    isContainRootNode: {
      type: Boolean,
      default: false
    },
    getTreeAction: {
      type: Function
    },
    map: {
      type: Object,
      default () {
        return {
          label: 'recipientName',
          value: 'recipientId',
          idKey: 'taskRecipientId'
        }
      }
    },
    isClearCache: { //是否清除缓存（v-model数据改变，树的选中未变化），默认不开启
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataTree: [],
      orgList: [],
      checkedList: [], //select的选择
      checkedOptions: [], //options
      showOrganModal: false,
      checkLevel: 'all', //选择级别
      checkType: [], //选择类型
      isContain: true, //是否包含下级
      treeRoot: [],
      handlerValue: '',
      treePath: [], //树的路径
      treeParamsMap: {
        scope: 2,
        orgId: sessionStorage.getItem('orgId')
      },
      treeMap: {
        key: 'orgId'
      }
    }
  },
  created () {
    // this.getTreeData()
    this.treeParamsMap = Object.assign(this.treeParamsMap, this.props.treeParamsMap)
    this.treeParamsMap.selectId = this.value.map(item => item[this.map.value])
    this.treeMap = Object.assign(this.treeMap, this.props.treeMap)
    this.params = Object.assign(this.params, this.props.params)
  },
  computed: {
    newTitle () {
      return this.params.modalTitle || '选择发送范围'
    },
    treeUrl () {
      return this.params.treeUrl || 'module/organization/getOrgTree'
    },
    treeParams () {
      return this.params.treeParams || {orgId: '-1'}
    },
    levelList () {
      return this.params.levelList || [{label: '下属全部', value: 'all'}, {label: '下一级', value: 'one'}]
    },
    typeList () {
      return this.params.typeList || [{label: '党委', value: '党委'}, {label: '党总支', value: '党总支'}, {label: '党支部', value: '党支部'}]
    },
    treeType () {
      return this.params.treeType || {type: 'multiple-all'}
    }
  },
  watch: {
    'props.treeParamsMap': {
      handler (newVal) {
        this.treeParamsMap = Object.assign(this.treeParamsMap, newVal)
      },
      deep: true
    },
    isContain (newVal) {
      this.$methods('tree', 'setCheck', newVal)
    },
    value: {
      handler (newVal) {
        this.orgList = newVal.length ? this.convertersParams() : []
        this.setSelectOption()
        this.treeParamsMap.selectId = this.value.map(item => item[this.map.value])
        if (this.isClearCache) {
          this.getTreeData()
          // this.resetTree()
          // const currentIds = this.value.map(item => item[this.map.value])
          // this.getMatchingNode(this.dataTree[0], currentIds)
        }
      },
      deep: true,
      immediate: true
    }
  },
  inject: {
    expandParent: {
      type: Function,
      default () {
        return function () {}
      }
    }
  },
  methods: {
    setValue () {
      let str = ''
      this.checkedOptions.length && this.checkedOptions.forEach(item => {
        str += item.label + '、'
      })
      this.handlerValue = str.substr(0, str.length - 1)
    },
    //快速重选
    quickSearch () {
      if (!this.checkType.length) return this.$Message.warning('请先选择机构类型')
      if (!this.treeRoot.length) this.treeRoot = this.$methods('tree', 'getCheckedNodes') || []
      this.treePath = [] //清空已添加的路径
      //const search = throttle((this.treeRoot.length ? this.setNodeChecked : this.recursionFunction), 1000, {leading: true, trailing: false})
      const search = throttle(this.recursionFunction, 1000, {leading: true, trailing: false})
      search()
      //获取选中的节点
      setTimeout(() => {
        const checkedNodes = this.$methods('tree', 'getCheckedNodes')
        this.orgList = checkedNodes.map(item => ({
          options: item,
          label: item.title,
          value: item.id,
          nodeKey: item.nodeKey
        }))
      }, 100)
    },
    //设置node
    setNodeChecked () {
      this.treeRoot.map(item => {
        const node = item.node
        node.checked = true
        this.$methods('tree', 'rebuildTreeDom', node)
        setTimeout(() => {
          const isContainRootNode = this.isContainRootNode ? true : (node.id != '-1')
          node.expand = true
          if (this.checkType.includes(node.data.orgCategory) && isContainRootNode) {
            if (!this.checkLevel) {
              node.checked = false
              this.$methods('tree', 'rebuildTreeDom', node)
            } else {
              this.recursionNode(node, false, this.checkLevel, true)
            }
          }
        })
      })
    },
    //直接修改数据
    recursionFunction () {
      //选择之前先将节点全部置为false
      let newTreeData = JSON.parse(JSON.stringify(this.$refs.tree.treeData[0]))
      this.resetNode(newTreeData, false)
      //设置节点选中
      if (this.isContainRootNode) { //是否包含根节点
        this.recursionCheckNode(newTreeData, true, this.checkLevel)
      } else {
        newTreeData.children && newTreeData.children.length && newTreeData.children.forEach(attr => {
          this.recursionCheckNode(attr, true, this.checkLevel)
        })
      }
      this.getCorrespondingNode(newTreeData, this.treePath, true) //展开被选中节点的父节点
      this.$set(this.$refs.tree.treeData, 0, newTreeData)
    },
    //重置所有节点
    resetNode (node, status) {
      node.checked = status
      if (node.id != '-1') {
        node.expand = status
      }
      node.children && node.children.length && node.children.map(val => {
        this.resetNode(val, status)
      })
    },
    //递归处理符合条件的节点
    recursionCheckNode (node, status, type) {
      if (this.checkType.includes(node.data.orgCategory)) {
        if (!type) {
          const path = node.treePath.split('/').filter(item => item)
          path.pop()
          this.treePath = this.treePath.concat(path)
          node.checked = status
        } else {
          this.recursionNode(node, status, type)
        }
      }
      node.children && node.children.length && node.children.map(val => {
        this.recursionCheckNode(val, status, type)
      })
    },
    //递归处理子节点
    recursionNode (node, status, type, isSingle) {
      node.children && node.children.length && node.children.map(val => {
        const path = val?.data?.dataPath?.split('/').filter(item => item) || val.treePath.split('/').filter(item => item)
        path.pop()
        this.treePath = this.treePath.concat(path)
        val.checked = status
        if (isSingle) this.$methods('tree', 'setCheckNode', val, status)
        if (type == 'all') this.recursionNode(val, status, type, isSingle)
      })
    },
    //获取对应的节点 为了做树的展开
    getCorrespondingNode (node, ids, status) {
      if (ids.includes(node.id) && !node.expand) {
        node.expand = status
      }
      node.children && node.children.length && node.children.map(val => {
        this.getCorrespondingNode(val, ids, status)
      })
    },
    //获取树的数据
    getTreeData () {
      let action = this.$ajax
      let params = {
        url: this.treeUrl,
        method: this.userAsync ? 'POST' : 'get',
        data: this.treeParams
      }
      let getData = (data) => {
        return data.data.data
      }
      if (this.getTreeAction) {
        action = this.getTreeAction
        params = this.treeParams
        getData = (data) => {
          return data
        }
      }
      action(params).then((data) => {
        this.dataTree = getData(data) || []
        if (this.value.length) {
          const currentIds = this.value.map(item => item[this.map.value])
          this.getMatchingNode(this.dataTree[0], currentIds)
        }
      })
    },
    //树的回显
    getMatchingNode (obj, ids) {
      if (ids.includes(obj.id)) {
        this.$set(obj, 'checked', true)
      }
      if (obj.children && obj.children.length) {
        obj.children.forEach(item => {
          this.getMatchingNode(item, ids)
        })
      }
    },
    onSelectChange (data, type) {
      if (type && type == 'search') {
        setTimeout(() => {
          // data[0].checked = true
          const checkedNodes = this.$methods('tree', 'getCheckedNodes')
          this.orgList = checkedNodes.map(item => ({
            options: item,
            label: item.title,
            value: item.id,
            nodeKey: item.nodeKey
          }))
        })
      }
    },
    //树节点选择
    onSelectChangeTree (data, root) {
      // if (this.isContain) this.recursionNode(data, !isCheckd, 'all', true)
      // setTimeout(() => {
      //   this.treeRoot = root
      //   const checkedNodes = this.$methods('tree', 'getCheckedNodes')
      //   this.orgList = checkedNodes.map(item => ({
      //     options: item,
      //     label: item.title,
      //     value: item.id,
      //     nodeKey: item.nodeKey
      //   }))
      // })
      // if (this.isContain) this.recursionNode(root[0], !root[0].checked, 'all', true)
      setTimeout(() => {
        this.treeRoot = data
        const checkedNodes = this.$methods('tree', 'getCheckedNodes')
        this.orgList = checkedNodes.map(item => ({
          options: item,
          label: item.title,
          value: item.id,
          nodeKey: item.nodeKey
        }))
      })
    },
    //tag取消选择
    onTagClose (val) {
      // if (!this.treeRoot.length) this.treeRoot = this.$methods('tree', 'getTreeRootNode') || []
      // const treeItem = this.treeRoot.find(item => item.node.id === val)
      // this.$methods('tree', 'rebuildTreeDom', treeItem.node)
      // setTimeout(() => {
      //   const checkedNodes = this.$methods('tree', 'getCheckedNodes')
      //   this.orgList = checkedNodes.map(item => ({
      //     options: item,
      //     label: item.title,
      //     value: item.id,
      //     nodeKey: item.nodeKey
      //   }))
      // })
      this.treeRoot = this.$methods('tree', 'getCheckedNodes') || []
      const treeItem = this.treeRoot.find(item => item.id === val)
      this.$methods('tree', 'setCheckNode', treeItem, false)
      setTimeout(() => {
        const checkedNodes = this.$methods('tree', 'getCheckedNodes')
        this.orgList = checkedNodes.map(item => ({
          options: item,
          label: item.title,
          value: item.id,
          nodeKey: item.nodeKey
        }))
      })
    },
    //重置
    resetTree () {
      this.checkType = []
      this.checkLevel = ''
      this.orgList = []
      this.isContain = true
      this.$methods('tree', 'resetTree')
      // let newTreeData = JSON.parse(JSON.stringify(this.dataTree[0]))
      // this.resetNode(newTreeData, false)
      // this.$set(this.dataTree, 0, newTreeData)
    },
    cancleTree () {
      this.checkType = []
      this.checkLevel = ''
      this.orgList = []
      this.showOrganModal = false
    },
    convertersParams () {
      return this.value.map(item => ({
        label: item[this.map.label],
        value: item[this.map.value],
        [this.map.idKey]: item[this.map.idKey] || ''
      }))
    },
    //设置下拉框的数据
    setSelectOption (type) {
      this.checkedOptions = this.orgList.map(item => ({
        ...item,
        [this.map.value]: item.value,
        [this.map.label]: item.label
      }))
      this.checkedList = this.orgList.map(item => item.value)
      this.setValue()
      if (!type && this.value.length) {
        const currentVal = this.convertersParams()
        this.checkedOptions.forEach(item => {
          const currentItem = currentVal.find(val => val.value === item.value)
          if (currentItem) {
            item[this.map.idKey] = currentItem[this.map.idKey]
          }
        })
      }
    },
    //确认
    onOK () {
      this.setSelectOption(true)
      this.expandParent('term', this.checkedOptions, this.attrKey)
      this.$emit('on-change', this.checkedOptions)
      this.showOrganModal = false
    },
    showModal () {
      this.showOrganModal = true
    }
  },
  components: {
    Modal,
    LazyTree,
    tagGroup,
    ModalLayout
  }
}
</script>

<style lang="scss" scoped>
  @import "variable/variable.scss";
  .iv-append {
    position: absolute;
    right: 3px;
    top: 20px;
    padding: 0 9px;
    color: #495060;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #eee;
    border: 1px solid #dddee1;
  }
  .iv-search-organ {
    border: 1px solid $iv-border-color;
  }
  .iv-content {
    border: 1px solid $iv-border-color;
  }
  .iv-content-left {
    height: 308px;
    overflow: hidden;
    border-right: 1px solid $iv-border-color;
    /deep/ .iv-tree-box {
      height: 230px;
      overflow: auto;
    }
    .iv-is-contain {
      width: 100%;
      height: 32px;
      line-height: 32px;
      margin-left: -8px;
      position: absolute;
      bottom: 0;
      background: $iv-main-bg-color;
    }
  }
  .iv-content-right {
    ul {
      width: 100%;
      height: 292px;
      white-space: nowrap;
      overflow: auto;
    }
  }
</style>
