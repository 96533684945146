<template>
  <FormItem :label="item.title" :prop="item.key" class="iv-pos-r" :class="{'iv-visable': item.props && item.props.isHidden}">
    <template slot="label" v-if="item.props && item.props.extra">
      <span>{{item.title}}</span>
      <!-- 动态表单的字段说明 -->
      <span v-if="item.description">
        <Poptip trigger="hover" :content="item.description" placement="right" popper-class="iv-form-dynamic-poptip">
          <IconFont type="jieshao1" class="iv-m-l-12"></IconFont>
        </Poptip>
      </span>
      <span v-else>
        <span class="iv-pull-right iv-fs-14 iv-pos-a iv-custom-form-extra">
          <IconFont type="question-circle" class="iv-m-r-4"></IconFont>
          <span>定级说明</span>
        </span>
      </span>
    </template>
    <Select
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      v-bind="item.props"
      v-on="item.on || {}"
      @on-change='handleChange(item, model[item.key], index)'
      :multiple="item.props && item.props.multiple"
      v-if="item.type === 'Select'">
      <Option :key="$index" :value="opt.value" v-for="(opt, $index) in item.optionList">{{opt.label}}</Option>
    </Select>
    <Select
      ref="SelectTireTree"
      :disabled="(item.readOnly === false || item.readOnly == void 0) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      v-bind="item.props"
      v-on="item.on || {}"
      filterable
      class="iv-select-tire-tree"
      :clearable="(item.readOnly === false || item.readOnly == void 0) ? !inReadOnly : !item.readOnly"
      @on-change='handleDatePickerChange(item, model[item.key], ...arguments)'
      v-else-if="item.type === 'SelectTree'">
      <Option
        :key="i + k.value"
        :value="k.value"
        :disabled="k.value == 'disabled'"
        class="iv-select-item"
        v-for="(k, i) in item.optionCacheList"
      >{{k.label}}</Option>
      <!-- 同步树 -->
      <TireTree
        v-model="model[item.key]"
        v-if="item.props.treeType == 'tireTree'"
        v-bind="item.props"
        v-on="item.on || {}"
        @on-bfs="onBfs(item, item.key, ...arguments)"
        @on-selectree-change="onSelectChange(item, item.key, ...arguments)"
      >
      </TireTree>
    </Select>
    <RemoteSelect
      :readonly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      v-else-if="item.type === 'RemoteSelect'"
      v-bind="item.props"
      @on-change="handleDatePickerChange(item, model[item.key], ...arguments)"
      v-on="item.on || {}">
    </RemoteSelect>
    <SelectOrganize
      ref="SelectOrganize"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :props="item.props"
      :map="item.map"
      :attrKey="item.key"
      v-model="model[item.key]"
      v-bind="item"
      v-on="item.on || {}"
      @on-change='handleChangeInputBlur(item, model[item.key])'
      v-else-if="item.type == 'selectOrganize'"></SelectOrganize>
    <UniversalTree
      ref="universalTree"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :valueReadOnly="item.change"
      :props="item"
      :keyValue="item.key"
      :listProps="item.props"
      v-model="model[item.key]"
      v-bind="item.props"
      v-on="item.on || {}"
      @on-change-tree="onChangeTreeValue(...arguments, item, model[item.key])"
      v-else-if="item.type == 'universalTree'"></UniversalTree>
    <SelectInputMember
      ref="selectInputMember"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :readonly="item.change"
      :actionType="item.props.actionType"
      :props="item"
      :listProps="item.props"
      v-model="model[item.key]"
      v-bind="item.props"
      v-on="item.on || {}"
      @on-change-all="changeAll(...arguments, item.key)"
      @on-change-tree="onChangeTreeValue(...arguments, item, model[item.key])"
      @on-change='handleChangeInputBlur(item, model[item.key])'
      v-else-if="item.type == 'selectInputMember'">
    </SelectInputMember>
    <RadioGroup
      v-bind="item.props"
      v-on="item.on || {}"
      @on-change='handleChange(item, model[item.key], index)'
      v-model="model[item.key]"
      v-else-if="item.type === 'Radio'">
      <Radio
        :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly" 
        :key="$index" 
        :label="opt.value" 
        v-for="(opt, $index) in item.optionList">
        <span class="iv-p-l-4">{{opt.label}}</span>
      </Radio>
    </RadioGroup>
    <CheckboxGroup
      v-bind="item.props"
      v-on="item.on || {}"
      @on-change='handleChange(item, model[item.key], index)'
      v-model="model[item.key]"
      v-else-if="item.type === 'Checkbox'">
      <Checkbox
        :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
        :key="$index" 
        :label="opt.value" 
        v-for="(opt, $index) in item.optionList">
        <span class="iv-p-l-8">{{opt.label}}</span>
      </Checkbox>
    </CheckboxGroup>
    <!-- 舆情管理所需业务组件 -->
    <MultipleCheckbox
      v-model="model[item.key]"
      :childVal="model[item.childKey]"
      :optionList="item.optionList"
      :childOptionList="item.childOptionList"
      :field="item.key"
      :disabled="(item.readOnly === false || item.readOnly == void 0) ? inReadOnly : item.readOnly"
      v-else-if="item.type === 'MultipleCheckbox'"
      @on-child-select="childCheck"
    ></MultipleCheckbox>
    <DatePicker
      :options="item.props && item.props.option"
      :readonly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      class="iv-datepicker-form"
      v-bind="item.props"
      v-on="item.on || {}"
      v-else-if="item.type === 'DatePicker'"
      @on-change="handleChange(item, model[item.key], index)">
    </DatePicker>
    <FileUpload
      :readOnly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      :getUploadFiles="model[item.key]"
      class="iv-upload-form"
      v-bind="item.props"
      v-on="item.on || {}"
      :keyValue="item.key"
      v-else-if="item.type === 'FileUpload' || item.type === 'fileUpload'"
      @on-input-clear="onClearFile(...arguments, item)"
      @on-upload-success="uploadSuccess(...arguments, item)"
      @on-change="handleChange(item, model[item.key], index)">
    </FileUpload>
     <FileUploadOne
       ref="FileUpload"
       :disabled="item.disabled"
       :readOnly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
       v-bind="item.props"
       :data="item.props.data"
       :formatText="item.props.formatText"
       :multiple="item.props.multiple"
       :accept="item.props.accept"
       v-on="item.on || {}"
       @on-input-clear="onClearFile(...arguments, item)"
       @on-upload-success="uploadSuccess(...arguments, item)"
       v-model="model[item.key]"
       :getUploadFiles="model[item.key]"
       :keyValue="item.key"
       v-else-if="item.type == 'fileUploadOne'">
     </FileUploadOne>
    <ImgUpload
      class="iv-photo-form"
      :readOnly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-bind="item.props"
      :keyValue="item.key"
      v-on="item.on || {}"
      @on-upload-success="uploadSuccess(...arguments, item)"
      v-else-if="item.type === 'photo'">
    </ImgUpload>
     <ImgCrop
      :option="item"
      name="multipartFiles"
      :readOnly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      @on-upload-success="uploadSuccess(...arguments, item)"
      :styles="{'height': item.representative ? '272px' : '129.75px'}"
      v-bind="item.props"
      v-else-if="item.type === 'photoCut'"
      v-on="item.on || {}" />
    <Input
      :readonly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      v-model="model[item.key]"
      type="textarea" 
      v-bind="item.props"
      :rows="(item.props && item.props.rows) ? item.props.rows : 3"
      @on-blur='handleInputBlur(item, model[item.key])'
      v-else-if="item.type === 'textarea'">
    </Input>
    <InputNumber
      :style="{width: item.props && item.props.unit ? '90%' : '100%'}"
      :readonly="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly"
      :precision="(item.props && item.props.precision) ? item.props.precision : 0"
      v-model="model[item.key]"
      v-bind="item.props"
      :rows="(item.props && item.props.rows) ? item.props.rows : 3"
      @on-blur='handleInputBlur(item, model[item.key])'
      v-else-if="item.type === 'Number' || item.type === 'InputNumber'">
    </InputNumber>
    <Input
      :style="{width: item.props && item.props.unit ? '90%' : '100%'}"
      v-model="model[item.key]" 
      :disabled="(item.readOnly === false || item.readOnly == undefined) ? inReadOnly : item.readOnly" 
      v-bind="item.props" 
      @on-blur='handleInputBlur(item, model[item.key])'
      v-else>
    </Input>
    <span class="iv-m-l-8 iv-fs-16 iv-fc-65" v-if="item.props && item.props.unit">{{item.props.unit}}</span>
  </FormItem>
</template>

<script>
import TireTree from '../tire-tree'
import RemoteSelect from '../remote-select'
import FileUpload from '@/components/upload'
import FileUploadOne from '@/components/file-upload-layout'
import IconFont from '@/components/iconfont'
import ImgUpload from '@/components/img-upload'
import ImgCrop from '@/components/image-crop'
import SelectInputMember from '@/components/select-input-member'
import SelectOrganize from '@/components/select-organize'
import UniversalTree from '@/components/universal-tree'
import MultipleCheckbox from '@/components/checkbox-multiple'
  
export default {
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    inReadOnly: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    onClearFile () {
      this.$emit('on-input-clear', ...arguments)
    },
    uploadSuccess () {
      this.$emit('on-upload-success', ...arguments)
    },
    //radio  Select等的change事件
    handleChange () {
      this.$emit('on-change', ...arguments)
    },
    handleInputBlur (item, data) {
      this.$emit('on-blur', item, data)
    },
    changeAll () {
      this.$emit('on-change-all', ...arguments)
    },
    onChangeTreeValue (data, keyValue, item, model) {
      this.$emit('on-change-tree', data, keyValue)
      this.$emit('on-change-diff', data, keyValue, item, model)
    },
    handleChangeInputBlur (item, val) {
      this.$emit('on-change-tree', item, val, this.id, 'tree', this.dwShortName, this.valList)
    },
    childCheck (val, key) {
      this.$emit('on-child-checkbox-change', val, key)
    },
    forceWatch (ref) {
      const { getInitialValue, getOptionData, publicValue, value } = ref
      ref.checkUpdateStatus()
      if (value === '') ref.values = []
      else if (JSON.stringify(value) !== JSON.stringify(publicValue)) {
        this.$nextTick(() => (ref.values = getInitialValue().map(getOptionData).filter(Boolean)))
      }
    },
    onSelectChange (item, type, list) {
      if (item.type == 'SelectTree') {
        this.setSelectCacheValue({
          title: item.title,
          key: item.key,
          option: list[0],
          disabledOrg: item.disabledOrg
        }, item)
      }
      this.$nextTick(() => {
        if (this.$refs.SelectTireTree) {
          const tireTrees = Array.isArray(this.$refs.SelectTireTree) ? this.$refs.SelectTireTree : [this.$refs.SelectTireTree]
          tireTrees.forEach(ref => {
            if (ref.value === list[0].value) {
              ref.$parent.onFieldChange()
              this.forceWatch(ref)
              ref.visible = false //解决党代表bug  点击完后下拉面板没收起
            }
          })
        }
        this.$set(this.model, type, list[0].value)
      })
      this.$emit('on-selectree-change', list, type)
    },
    setSelectCacheValue (params, val) {
      let list = JSON.parse(localStorage.getItem(params.key + '[' + params.title + ']') || '[]')
      if (list.length >= 3) {
        list.splice(0, 1)
        list.splice(list.length - 1, 1)
      }
      list = list.filter((item) => {
        return item.value !== params.option.value
      })
      list.unshift({
        label: '最近选项',
        value: 'disabled'
      })
      list.push({
        label: '所有选项',
        value: 'disabled'
      })
      if (list.length >= 7) {
        list.splice(5, 1)
      }
      list.splice(1, 0, params.option)
      localStorage.setItem(params.key + '[' + params.title + ']', JSON.stringify(list.splice(0, 7)))
      this.getCacheOptionList(params, val)
    },
    getCacheOptionList (item, val) {
      item.optionCacheList = []
      if (!this.model[item.key]) {
        this.$set(this.model, item.key, '')
      }
      let list = JSON.parse(localStorage.getItem(item.key + '[' + item.title + ']') || '[]')
      list.map((op, index) => {
        if (item.disabledOrg && !item.disabledOrg.includes(op.label)) {
          list.splice(index, 1)
        }
      })
      val.optionCacheList = list
      this.$forceUpdate()
    },
    onBfs (item, type, list) {
      this.$set(item, 'optionAll', list)
    },
    async handleDatePickerChange (item, val, $index) {
      // await this.changeUnique(...arguments)
      this.$emit('on-change', item, val, $index)
    }
  },
  components: {
    TireTree,
    IconFont,
    ImgUpload,
    FileUpload,
    FileUploadOne,
    SelectOrganize,
    UniversalTree,
    RemoteSelect,
    SelectInputMember,
    MultipleCheckbox,
    ImgCrop
  }
}
</script>

<style lang="scss">
  .ivu-form-item-error-tip {
    position: absolute;
    left: auto;
    right: 4px !important;
    top: -24px !important;
  }
  .iv-select-item.ivu-select-item-disabled {
    background-color: #f3f3f3;
  }
  .iv-form-dynamic-poptip {
    text-align: left;
    min-width: auto !important;
    max-width: 300px;
    .ivu-poptip-inner {
      white-space: normal;
    }
  }
</style>