<template>
  <FormItem
    class="iv-imgupload-wrapper"
    :label="option.representative ? option.title : ''"
  >
    <div
      class="iv-img-upload iv-pointer iv-text-center file-uploads file-uploads-html5"
      @click="showModal"
    >
      <div
        class="iv-mask iv-text-center iv-fs-24"
        v-if="!readOnly"
      >
        <Icon
          class="iv-white iv-fs-24"
          type="android-camera"
        ></Icon>
        <p class="iv-white iv-fs-16 iv-hover-color">点击上传</p>
      </div>
      <div
        class="iv-image-view"
        :style="{...styles, 'background-image': `url(${previewSrc ? previewSrc : require('@/assets/images/user/default-pic.png')})`}"
      ></div>
    </div>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgone"
    >建议上传图片比例为8：5（宽：高）</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgtwo"
    >建议上传图片比例为2：1（宽：高）</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgthree"
    >建议上传图片比例为3：2（宽：高）</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgfour"
    >建议上传图片比例为1：1（宽：高）</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgfive"
    >建议上传图片比例为4：3（宽：高）, 例如：400 * 300</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgsix"
    >建议上传图片比例为15：6（宽：高）, 例如：1200 * 470</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgseven"
    >建议上传图片比例为12：5（宽：高）, 例如：1200 * 500</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgnine"
    >建议上传图片比例为16：9（宽：高）, 例如：1600 * 900</p>
    <p
      class="iv-red-color iv-text-center"
      v-if="photofgTen"
    >建议上传图片比例为11：5（宽：高）, 例如：440 * 200</p>
    <BigModal
      :value="coverPhotoVal"
      @on-ok="saveUpload"
      @on-cancel="cancelUpload"
      ref="coverPho"
      title="图片"
    >
      <div slot="modal-content">
        <ImageCrop
          :cropBoxResizable="true"
          ref="imageCrop"
          :imgUrl="fileUrl"
          :imgName="fileName"
          :uploadUrl="url"
          :uploadData="uploadData"
          :crop="crop"
          :isUploaded.sync='isUploaded'
        ></ImageCrop>
      </div>
    </BigModal>
  </FormItem>
</template>
<script>
import BigModal from '@/components/study-big-modal'
import IconFont from '@/components/iconfont'
import ImageCrop from './crop'
import { prefixPath } from '@/config'
export default {
  props: {
    url: {
      type: String,
      default: 'api-uum/file/upload'
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    },
    data: {
      type: Object,
      default() {
        return {
          groupId: 'tupian',
          uploadUser: '123'
        }
      }
    },
    src: {
      type: String,
      default: require('@/assets/images/user/default-pic.png')
    },
    autoActive: {
      type: Boolean,
      default: true
    },
    option: {
      type: Object,
      default() {
        return {}
      }
    },
    photofgone: {
      type: Boolean,
      default: false
    },
    photofgtwo: {
      type: Boolean,
      default: false
    },
    photofgthree: {
      type: Boolean,
      default: false
    },
    photofgfour: {
      type: Boolean,
      default: false
    },
    photofgfive: {
      type: Boolean,
      default: false
    },
    photofgsix: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    photofgseven: {
      type: Boolean,
      default: false
    },
    photofgnine: {
      type: Boolean,
      default: false
    },
    photofgTen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      coverPhotoVal: false,
      uploadFiles: [],
      fileUrl: '',
      fileName: '',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
      },
      uploadData: Object.assign(this.data, {
        name: 'multipartFiles'
      }),
      previewSrc: this.src,
      crop: {
        aspectRatio: 5 / 5
      },
      isUploaded:false
    }
  },
  watch: {
    src: {
      //动态表单特殊处理 时间紧迫 没时间追查问题  暂时这样处理   err：props传递过来数据候  组件会重置一次
      handler (newVal, oldVal) {
        if (newVal === '' && oldVal) {
             this.previewSrc = oldVal
           } 
        else if(newVal === '' && oldVal==undefined){
           if(this.photofgTen){
             //可编辑页面
            this.previewSrc = require('@/assets/images/user/default-pic.png')
           }
           else{
            this.previewSrc = require('@/assets/images/user/default-pic2.png')
           }          
        }
        else {
           this.previewSrc = newVal
        }
      },
      immediate: true
    },
    data(val) {
      this.uploadData = Object.assign(val, {
        name: 'multipartFiles'
      })
    }
  },
  components: {
    ImageCrop,
    BigModal,
    IconFont
  },
  methods: {
    // crop Star
    showModal() {
      this.coverPhotoVal = !this.readOnly
      let crops = this.photofgone ? 8 / 5 : this.photofgtwo ? 2 / 1 : this.photofgthree ? 3 / 2 : this.photofgfive ? 4 / 3 : this.photofgsix ? 15 / 6 : this.photofgseven ? 12 / 5 : this.photofgnine ? 16 / 9 : this.photofgTen ? 11 / 5 : 1 / 1
      this.$set(this.crop, 'aspectRatio', crops)
      // this.getCoverImgInfo()
    },
    getCoverImgInfo() {
      // if (this.coverInfo.headImg) {
      //   this.fileUrl = this.coverInfo.headImg
      //   this.getImgSrc('imgUrl', 'headImg', this.coverInfo.headImg)
      // } else {
      //   this.fileUrl = null
      //   this.getImgSrc('imgUrl', 'headImg')
      // }
    },
    saveUpload() {            
      if(!this.isUploaded){
        this.$Message.error('请先上传图片')
        return
      }
      else{
        this.$Message.success('正在上传')
      }
      this.$refs.imageCrop.onFileUpload().then(res => {
        if (res.success) {
          this.fileId = res.data.fileId
          this.fileName = res.data.fileName
          this.previewSrc = `api-uum/file/downloadFile?fileId=${res.data.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
          this.$emit('on-upload-success', Object.assign({}, res, {
            response: res
          }))
          // this.coverInfo.headImg = this.fileId
          // this.getCoverImgInfo()
          this.coverPhotoVal = false
          this.isUploaded = false
        } else {
          this.$Message.error(res.message ? res.message : '上传失败')
          this.isUploaded = false
        }
      })
    },
    cancelUpload() {
      this.coverPhotoVal = false
    },
    // crop End
    clear(item) {
      this.$refs.fileUpload.clear()
    },
    inputFile(newFile, oldFile) {
      this.$emit('on-input-file', newFile)
      if (newFile && oldFile) {
        if (newFile.success && !oldFile.success) {
          if (newFile.response.code == '0' || newFile.response.status == 'success') {
            this.$emit('on-upload-success', newFile)
          } else {
            this.$emit('on-upload-error', newFile)
          }
          this.clear()
        }
      }
      if (Boolean(newFile) !== Boolean(oldFile) || newFile.error !== oldFile.error) {
        if (!this.$refs.fileUpload.active) {
          let isImg = /(png|jpe?g|gif)\s*$/.test(newFile.type)
          let name = (newFile && newFile.name) || (oldFile && oldFile.name)
          let suffix = name.slice(name.lastIndexOf('.') + 1)
          let isAccept = this.accept.indexOf(suffix) > -1 || this.accept === '*'
          if (isAccept || isImg) {
            this.$refs.fileUpload.active = this.autoActive
            try {
              this.previewSrc = URL.createObjectURL(newFile.file)
            } catch (e) {
              this.previewSrc = ''
            }
            return
          }
          this.$Message.error(`文件类型不匹配`)
        }
      }
    },
    startUpload() {
      this.$refs.fileUpload.active = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "variable/variable.scss";
.iv-iconfont {
  font-size: 24px;
}
.iv-white {
  color: #fff;
}
.iv-imgupload-wrapper {
  height: 142.75px;
  .iv-image-view {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .iv-img-upload {
    line-height: 142.75px;
    // background: #ccc;
    border-radius: 4px;
    width: 100%;
    &:hover {
      .iv-mask {
        transform: translateY(0);
      }
    }
    .iv-mask {
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: translateY(110%);
      transition: transform 0.3s linear 0s;
      display: table;
      i {
        display: table-cell;
        vertical-align: middle;
      }
      p {
        display: table-row;
      }
    }
    img {
      height: 100%;
    }
  }
}
</style>
