<template>
  <div class="iv-footer-operator-wrapper iv-text-center iv-symbol-mr16">
    <!-- iv-shadown-box -->
    <div class="iv-border"></div>
    <span
      class="iv-p-l-24 iv-fs-14 iv-pull-left iv-coverinfo iv-fc-65 uit-show-list"
      v-if="showCover">
      <Icon type="information-circled" class="iv-m-r-8 iv-main-color"></Icon>
      {{coverInfo}}
    </span>
    <slot>
      <Button
        type="save"
        noIcon
        class="uit-ok"
        @click="onOk">
        <span v-html="saveText"></span>
      </Button>
      <Button
        type="back"
        noIcon
        class="iv-m-l-8 uit-cancel"
        @click="onCanel">
        <span v-html="cancelText"></span>
      </Button>
    </slot>
  </div>
</template>
<script>
import Button from '@/components/button'
import {mapGetters} from 'vuex'
export default {
  props: {
    coverInfo: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: '取&nbsp;消'
    },
    saveText: {
      type: String,
      default: '保&nbsp;存'
    },
    showCover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'showMenu'
    ])
  },
  created () {
    this.isPink = /^\/popUpPage\//.test(this.$route.path)
  },
  mounted () {
    if (!this.assert()) return
    this.changeClass('add')
    this.$on('hook:destroyed', () => {
      if (!this.assert()) return
      this.changeClass('remove')
    })
    this.$on('hook:deactivated', () => {
      if (!this.assert()) return
      this.changeClass('remove')
    })
    this.$on('hook:activated', () => {
      this.changeClass('add')
    })
  },
  methods: {
    onCanel () {
      this.$emit('on-cancel')
    },
    onOk () {
      this.$emit('on-ok')
    },
    getEl () {
      return document.querySelector('.iv-article-wrapper') || document.querySelector('.iv-slider-body')
    },
    assert () {
      let el = this.getEl()
      if (!el) return
      return true
    },
    changeClass (action) {
      let el = this.getEl()
      try {
        el.classList[action]('iv-not-detail-wrapper')
      } catch (err) {
        console.error(err)
      }
    }
  },
  components: {
    Button
  }
}
</script>
<style lang="scss" scoped>
@import 'variable/variable.scss';
// @import "variable/layout.scss";
.iv-footer-operator-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 9;
  padding: 0;
  padding-bottom: 12px;
  text-align: right;
  .iv-border{
    border-top: 1px solid rgba(0, 0, 0, .15);
  }
  transition: left .3s linear 0s;
  &.left0 {
    left: 0 !important;
  }
}
</style>