export default function () {
  return [
    {
      title: '党组织编码',
      [$field]: 'orgCode'
    },
    {
      title: '党组织全称',
      [$field]: 'orgName'
    },
    {
      title: '上级党委',
      [$field]: 'parentOrgName'
    }
  ]
}