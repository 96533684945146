<template>
  <div class="iv-remote-wrapper">
    <Tree
      :data="data" 
      @on-select-change="handleCheckChange" 
      disableCheckbox></Tree>
  </div>
</template>
<script>
import {BFS} from '@/utils'
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default () {
        return {
          title: 'title',
          value: 'value'
        }
      }
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    method: {
      type: String,
      default: 'GET'
    },
    optionList: {
      type: Array,
      default () {
        return []
      }
    },
    filterable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      loading: false,
      options: this.optionList,
      inValue: this.value,
      trueWatch: false,
      list: []
    }
  },
  created () {
    this.remoteMethod()
  },
  inject: {
    onSearchChange: {
      default () {
        return function () {}
      }
    }
  },
  watch: {
    value (val) {
      this.inValue = val
      this.$nextTick(()=> {
        this.initSelectData()
      })
    },
    optionList (val) {
      this.options = val
    }
  },
  methods: {
    BFS,
    handleCheckChange (data) {
      let value = ''
      let label = ''
      this.selectlList = []
      this.modelSelectTree = ''
      data.forEach((item, index) => {
        value = item.data.itemCode
        label = item.title
      })
      this.selectlList.push({
        value,
        label
      })
      this.$emit('on-selectree-change', this.selectlList)
    },
    remoteMethod (query) {
      if (!this.url) return
      this.$ajax({
        url: this.url,
        method: this.method,
        data: this.params
      }).then(({data}) => {
        this.data = data.data
        this.searchCode(this.data)
        this.initSelectData()
      })
    },
    searchCode (arr) {
      arr.forEach((item, key) => {
        item.children && item.children.length && this.searchCode(item.children)
        if (item.data.itemCode && !item.disabled) {
          item.title = item.data.itemCode + '-' + item.title
        }
      })
    },
    initSelectData () {
      this.list = []
      let value = Array.isArray(this.inValue) ? this.inValue : [this.inValue]
      this.BFS(this.data, (item) => {
        item.value = item.data.content
        item.label = item.title
        this.list.push(item)
        if (value.includes(this.getId(item))) {
          item.selected = true
          this.selectlList = []
          this.selectlList.push({
            value: item.data.itemCode,
            label: item.title
          })
          this.$emit('on-selectree-change', this.selectlList)
        }
        if (item.selected && !value.includes(this.getId(item))) {
          item.selected = false
        }
        this.$emit('on-bfs', this.list)
      })
    },
    getId (item) {
      return item?.data?.itemCode
    },
    onChang (val) {
      this.$emit('input', val)
      this.$emit('on-change', this.options, val)
    }
  }
}
</script>
<style lang="scss">
.iv-remote-wrapper{
  .ivu-select-input{
    height: 32px !important;
    line-height: 32px !important;
  }
}
</style>
