<template>
  <div
    :class="{'iv-reset': reset}"
    class="iv-import-wrapper">
    <div class="iv-importDown-bottom">
      <!--??改成upLoad" @click="pictureSize(item)"-->
      <div
        style="overflow: hidden;"
        class="iv-importDown-list iv-pointer"
        v-for="(item, index) in uploadFiles"
        :key="index">
        <div class="iv-importDown-listImg iv-pull-left iv-m-t-16 iv-m-b-16">
          <img
            width="48"
            height="48"
            :src="fileTypeList[index]" alt="" class="uit-img-list"/>
            <!-- :src="imgSrc(item)" alt="" class="uit-img-list"/> -->
            <!-- item.fileTypeSrc -->
        </div>
        <slot
          name="progress"
          :showWrongClass="showWrongClass"
          :item="item"
          :uploadPercent="uploadPercent"
          :progessStatus="progessStatus"
          :readOnly="readOnly"
          :reUpload="reUpload"
          :pictureSize="pictureSize"
          :delUploadList="delUploadList"
          :showProgess="showProgess">
          <div class="iv-importDown-listContent iv-m-r-16">
            <p class="iv-fs-14 uit-name-list" :class="item.err ? 'iv-red-color' : 'iv-fc-65 up-hover-info'">
              <span @click="pictureSize(item, 'upload')">{{item.name}}
                <!-- <span class="iv-symbol-ml12" v-show="multiple === true">(点击文件名称可下载)</span> -->
                <!-- <span class="iv-symbol-ml12">(点击文件名称可下载)</span> -->
              </span>
              <Icon
                v-if="!readOnly"
                type="close"
                class="iv-fs-12 iv-pull-right re-uploadPoinr iv-m-l-16 uit-clo uit-click-list"
                @click="delUploadList(item, index)"
                :style="!showProgess ? '' : 'display:inline-block;'"></Icon>
            </p>
            <Progress
              class="uit-progress-list"
              :percent="Math.floor(item.progress?item.progress:100)"
              :hide-info='true'
              :stroke-width="2"
              v-show="showProgess">
            </Progress>
            <p class="iv-fs-14 iv-error-info" :style="!showProgess ? 'margin-top:20px;' : ''">
              <span class="uload-info iv-red-color" v-if="item.err">
                {{wrongTips}}
              </span>
              <span class="uload-info iv-fc-45 uit-show1-list" v-show="!showProgess">
                {{ item.newTime + '&nbsp;&nbsp;' + (item.person ?  item.person : '') }}
              </span>
              <span class="iv-m-l-24 re-upload iv-fc-45 uit-show2-list" v-show="!showProgess">
                附件大小&nbsp;{{ pictureSize((item.response && item.response.size) || item.size) }}
              </span>
              <!-- <span class="iv-m-l-24 re-upload uit-re iv-main-color uit-click1-list"
                    @click="reUpload(item)"
                    v-if="showWrongClass">
                重新上传
              </span> -->
              <slot name="innerExpand"></slot>
            </p>
          </div>
        </slot>
      </div>
      <div v-if="!uploadFiles.length && readOnly">暂无附件</div>
      <div class="iv-importDown-list uit-show-list" v-show="(multiple === true ? true : !uploadFiles.length) && !readOnly">
        <FileUpload
          :multiple="false"
          class="iv-ins-upload iv-pointer"
          v-model="uploadFiles"
          :accept="acceptType"
          :extensions="extensions"
          :post-action="url"
          :data="uploadData"
          :size="size"
          :timeout="timeout"
          :headers="headers"
          v-bind="$attrs"
          @input-file="inputFile"
          @input-filter="inputFilter"
          :chunk-enabled="false"
          :chunk="fileChunk"
          ref="fileUpload">
          <div class="iv-pull-left iv-ins-upload-firstP">
            <IconFont
              class="iv-iconfont iv-main-color"
              type="cloud-upload"></IconFont>
          </div>
          <div class="iv-pull-left iv-p-t-16 iv-ins-upload-info iv-pointer iv-fc-65">
            <p class="iv-fs-14 iv-text-left">
              {{ titleText }}
              <span class="iv-main-color">上传</span></p>
            <p
              v-if="typeof formatText === 'string'"
              class="iv-m-t-4 iv-fs-14" v-html="formatText"></p>
            <p
              v-else-if="Array.isArray(formatText)"
              class="iv-m-t-4 iv-fs-14">
              {{formatText[0]}}
              <span class='iv-main-color'>{{formatText[1]}}</span>
            </p>
          </div>
        </FileUpload>
      </div>
    </div>
    <slot name="expand" :uploadFiles="uploadFiles"></slot>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component'
import IconFont from '@/components/iconfont'
import {prefixPath} from '@/config'
import {timeFormat, isIE9} from '@/utils'
import { chunkUploadMixin } from '@/mixins/file-upload'

const MINSIZE = 10485760
export default {
  mixins: [chunkUploadMixin],
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: prefixPath + 'api-mock/file'
      // default: prefixPath + 'zuul/api-file/workbench/' + (isIE9() ? 'ie/file/saveFileHtml' : 'file') + '?_csrf=' + sessionStorage.getItem('token')
    },
    accept: {
      type: String,
      default: '.word,.pdf,.jpg,.jpeg,.png,.ppt,.pptx'
    },
    reset: {
      type: Boolean,
      default: false
    },
    isMuleiple: {
      type: Boolean,
      default: false
    },
    titleText: {
      type: String,
      default: '点击这里'
    },
    formatText: {
      type: [String, Array],
      default: '支持word、pdf、ppt、图片格式，附件大小不超过5M。请勿上传违反国家法律法规或不宜网络公开的资料。'
    },
    data: {
      type: Object,
      default () {
        return {
          override: true,
          path: '/cw'
        }
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    getUploadFiles: {
      type: Array,
      default () {
        return []
      }
    },
    preview: {
      type: Object,
      default () {
        return {}
      }
    },
    size: {
      type: Number,
      default: 5 * 1024 * 1024
    },
    timeout: {
      type: Number,
      default: 10 * 60 * 1000
    },
    autoClear: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    noDownloadFile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      uploadFiles: this.multiple == true ? this.getUploadFiles : (this.preview.type ? [this.preview] : []),
      headers: {
        'X-XSRF-TOKEN': sessionStorage.getItem('token'),
        'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      fileTypeSrc: '',
      showProgess: this.preview || !this.autoUpload ? '' : true,
      showWrongClass: false,
      uploadPercent: 0,
      progessStatus: 'normal',
      acceptType: '',
      extensions: '',
      fileTypeList: [],
      uploadData: {},
      wrongTips: '文件上传失败，请确保网络通畅后重新上传',
      fileChunk: {}
    }
  },
  components: {
    FileUpload,
    IconFont
  },
  watch: {
    data: {
      handler (newVal) {
        this.$set(this, 'uploadData', Object.assign(this.uploadData, newVal))
      },
      deep: true
    },
    preview: {
      handler (newVal) {
        let flag = !!newVal.type
        this.uploadFiles = flag ? [newVal] : []
        this.showProgess = this.autoUpload ? !flag : false
      },
      immediate: true
    },
    getUploadFiles: {
      handler (newVal) {
        if (newVal != null) {
          this.fileTypeList = []
          newVal.map((item, index) => {
            item.type = item.fileExt
            item.newTime = timeFormat(item.uploadTime)
            item.name = item.fileName
            item.imgType = this.getImgType(item.name)
            this.$set(item, 'fileTypeSrc', this.getType2Image(item.imgType))
            this.$set(this.fileTypeList, index, this.getType2Image(item.imgType))
          })
          this.uploadFiles = newVal
        }
      }
    }
  },
  methods: {
    setExternalData (data) {
      this.$set(this, 'uploadData', Object.assign(this.uploadData, data))
    },
    getPreviewSrc () {
      if (!this.preview || !this.preview.type) return ''
      let type = this.getImgType(this.preview.type)
      this.$set(this.preview, 'src', this.getType2Image(type))
      return this.preview.src || ''
    },
    getImgType (item) {
      let a = item.substring(item.lastIndexOf('.') + 1)
      let b = ''
      if (a == 'xlsx' || a === 'xls' || a === 'et') {
        b = 'xls'
      } else if (a == 'jpg' || a == 'png' || a == 'gif' || a == 'jpeg') {
        b = 'img'
      } else if (a == 'docx' || a == 'doc' || a == 'wps') {
        b = 'doc'
      } else if (a == 'ppt' || a == 'pptx' || a == 'dps') {
        b = 'ppt'
      } else if (a == 'txt') {
        b = 'txt'
      } else if (a == 'pdf' || a === 'ofd') {
        b = 'pdf'
      } else if (a == 'mp4' || a == 'MP4') {
        b = 'mp4'
      } else if (a == 'mp3') {
        b = 'mp4'
      } else if (a == 'zip' || a == 'tar') {
        b = 'zip'
      } else {
        b = 'xml'
      }
      return b
    },
    reUpload (item) {
      this.progessStatus = 'normal'
      this.showWrongClass = false
      this.$refs.fileUpload.remove(item)
    },
    delUploadList (item, index) {
      this.$confirmModal.warning({
        title: this.tips?.reqText?.delTitle || '提示',
        content: this.tips?.reqText?.delContent || '是否确认删除？'
      }).then(res => {
        const fileId = item?.response?.data?.fileId || item.fileId
        if (fileId) {
          this.deleteFileRequest(fileId).then(({data}) => {
            this.componetDel(item, index, res)
          })
        } else {
          this.componetDel(item, index, res)
        }
      })
    },
    //组件删除 封装
    componetDel (item, index, res) {
      for (var i = this.uploadFiles.length - 1; i >= 0; i--) {
        if (this.multiple == true) {
          //解决删除，文件名相同时，删除多个问题
          if (item.id != undefined && item.id === this.uploadFiles[i].id) {
            this.uploadFiles.splice(i, 1)
          } else if (item.id == undefined && item.fileId === this.uploadFiles[i].fileId) {
            this.uploadFiles.splice(i, 1)
          }
        } else {
          if (item.name === this.uploadFiles[i].name) {
            this.uploadFiles.splice(i, 1)
          }
        }
      }
      if (this.uploadFiles.length == 0) {
        this.$refs.fileUpload.clear()
      }
      this.fileTypeList.splice(index, 1)
      this.$emit('on-input-clear', this.$refs.fileUpload, item, this.uploadFiles, this.$attrs.keyValue)
      res()
    },
    //删除附件 接口
    deleteFileRequest (fileId) {
      return this.$ajax({
        url: '/api-gongjian/file/deleteFiles',
        method: 'delete',
        data: {
          fileIds: [fileId]
        }
      })
    },
    isEmptyObject (obj) {
      try {
        for (let i in obj) {
          if (obj.hasOwnProperty(i)) return true
        }
      } catch (err) {
        return false
      }
      return false
    },
    sendError (newFile, oldFile) {
      let error = newFile && newFile.error
      //错误信息提示
      if (error) {
        switch (error) {
          case 'size':
            this.wrongTips = '文件过大，请重新上传'
            this.$Message.error('文件过大，请重新上传')
            break
          case 'extension':
            this.wrongTips = '文件类型错误，请重新上传'
            this.$Message.error('文件类型错误，请重新上传')
            break
          case 'timeout':
            this.wrongTips = '文件上传超时，请重新上传'
            this.$Message.error('文件上传超时，请重新上传')
            break
        }
        this.uploadError(newFile)
        this.autoClear && this.$refs.fileUpload.clear()
        return true
      } else { //类型上传错误时 组件未返回错误类型  手动处理下
        let suffix = newFile ? newFile.name.split('.') : []
        const fileType = suffix.length ? suffix[suffix.length - 1] : ''
        if (fileType && error == '' && this.extensions.indexOf(fileType) == -1) {
          // this.wrongTips = '文件类型错误，请重新上传'
          this.wrongTips = '附件格式要求为："' + 'word,pdf,jpg,jpeg,png,ppt,pptx' + '"，请重新上传'
          this.$Message.error(this.wrongTips)
          this.uploadError(newFile)
          this.autoClear && this.$refs.fileUpload.clear()
          return true
        }
      }
      if (newFile) {
        if (newFile.size > Number(this.size)) {
          // this.$Message.error('文件上传失败，附件过大，请按规定大小上传文件')
          this.wrongTips = '附件要求为："' + this.formatText + '"，请重新上传'
          this.$Message.error('文件大小不能超过5M,请重新上传')
          // this.$refs.fileUpload.active = false
          this.uploadError(newFile)
          return true
        }
      }
      if (newFile && oldFile) {
        if (newFile.success && !oldFile.success) {
          if (isIE9()) {
            newFile.response.data = newFile.response
            newFile.size = newFile.response.size
          }
          if (newFile.response.code == '0' || newFile.response.status == 'success' || isIE9()) {
            // 源于file-upload mixins
            this.checkChunkUpload(newFile)
          } else {
            this.uploadError(newFile)
            return true
          }
        }
      }
    },
    sendClear () {
      if (this.clear) {
        //清空
        Object.keys(this.preview).forEach(key => {
          delete this.preview[key]
        })
        this.clear = false
        return true
      }
    },
    // 初始化上传状态
    sendInitUpload (newFile, oldFile) {
      if (Boolean(newFile) !== Boolean(oldFile) || newFile.error !== oldFile.error) {
        if (!this.$refs.fileUpload.active) {
          let name = (newFile && newFile.name) || (oldFile && oldFile.name)
          let suffix = name.slice(name.lastIndexOf('.') + 1)
          let isAccept = this.extensions.indexOf(suffix) > -1
          if (isAccept || this.accept === '*') {
            this.uploads = newFile
            this.$refs.fileUpload.active = this.autoUpload
            this.uploadFiles.forEach((item, index) => {
              item.newTime = timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
              item.imgType = this.getImgType(item.name)
              this.$set(this.fileTypeList, index, this.getType2Image(item.imgType))
            })
          }
        }
      }
      //更新上传进度
      if (newFile) {
        this.updatePercent()
        this.progessStatus = 'normal'
        this.showWrongClass = false
        this.showProgess = true
      }
    },
    inputFile (newFile, oldFile) {
      console.log(this.uploadFiles);     
      if(this.uploadFiles.length>10){
        this.uploadFiles.length = 10
        this.$Message.error('最多只能上传10个文件')
        return
      }
      if (this.sendError(newFile, oldFile)) return
      if (this.sendClear()) return
      if (this.isEmptyObject(this.preview)) return
      this.sendInitUpload(newFile, oldFile)
      this.$emit('on-input-file', newFile, oldFile)
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        //如果是chunk上传，我们要自定义上传进度
        if (isIE9() || newFile.size < MINSIZE) return
        const updateProgress = () => {
          this.updatePercent()
          //避免过度执行此函数，使用macrotask
          setTimeout(() => {
            updateProgress()
          }, 16.6)
        }
        this.$nextTick(updateProgress)
      }
    },
    updatePercent () {    
      if (this.uploadPercent >= 100) return
      let progress = (this.$refs.fileUpload.files[0] && this.$refs.fileUpload.files[0].progress) || 0 
      this.uploadPercent = ~~progress
    },
    uploadSuccess (newFile) {
      if (newFile.response.data.code == -1) {
        this.$emit('on-upload-error', newFile)
      } else {
        this.$emit('on-upload-success', newFile, this.$attrs.keyValue, newFile.response.data)
      }
    },
    uploadError (newFile) {
      this.progessStatus = 'wrong'
      this.showWrongClass = true
      const index = this.uploadFiles.findIndex((item) => item.id === newFile.id)
      if (index != -1) {
        const copyFile = this.uploadFiles[index]
        this.$set(this.uploadFiles, index, Object.assign({}, copyFile, { err: true, progessStatus: 'wrong' }))
      }
      this.$emit('on-upload-error', newFile)
    },
    normalizeType () {
      if (this.accept === '*') return
      const types = {
        'image': 'bmp, jpg, png, gif, svg, webp, bmp, jpe',
        // 'video': 'mp4, flv, webm, f4v, mkv, mpg, avi, wmv, mov, swf, ogg',
        // 'word': 'doc, docx, xls, xlsx, pdf, txt, md, xml, wps',
        'word': 'doc, docx, pdf, wps',
        // 'attachment': 'zip, rar, taz, gz'
      }
      let accept = this.accept.toLocaleLowerCase()
      // if: accept = 'image, video, .doc, jpg, mp4'
      accept = accept.split(',').reduce((result, type) => {
        // if: type === ' .jpg'
        type = (type = type.trim()).charAt(0) === '.' ? type.slice(1) : type
        // if: type === 'image, video, jpg'
        result.push(
          ...(types[type]
            ? types[type].split(',').map(t => t.trim())
            : [type])
        )
        return result
      }, [])
      /*
        * acceptType=".doc,.jpg,.xml" => MIME类型
        * extensions="jpg,gif,png" => vue-upload-component
        * */
      this.acceptType = `.${accept.join(',.')}`
      this.extensions = accept.join(',')
    },
    // 换算图片格式的大小
    pictureSize (size, data) {
      //去掉下载功能
      if (data != undefined && data == 'upload') {
        if (size.fileId == undefined) {
          size.fileId = size.response.data.fileId
        }
        let url = `${prefixPath.replace('api-platform','api-gongjian')}file/downloadFile?fileId=${size.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      }
      if (isNaN(size)) { // 判断是不是一个数
        return ''
      }
      let uniTsize = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] // 保存文件的格式
      let exp = Math.floor(Math.log(size) / Math.log(2)) // 对数函数
      if (exp < 1) {
        exp = 0
      }
      let i = Math.floor(exp / 10)
      size = size / Math.pow(2, 10 * i)
      if (size.toString().length > size.toFixed(2).toString().length) {
        size = size.toFixed(2)
      }
      return size + ' ' + uniTsize[i]
    },
    // 开始上传
    startUpload () {
      this.showProgess = true
      this.progessStatus = 'normal'
      this.showWrongClass = false
      this.uploadPercent = 0
      this.$refs.fileUpload.active = true
    },
    getType2Image (type) {
      return require(`@/assets/images/${type}@2x.png`)
    }
  },
  created () {
    this.normalizeType()
    this.uploadData = Object.assign({override: true, path: '/cw', currentAuthScopeId: sessionStorage.getItem('listOrgId')}, this.data)
    if (this.multiple == true && this.getUploadFiles && Array.isArray(this.getUploadFiles) && this.getUploadFiles.length) {
      this.uploadFiles = []
      this.getUploadFiles.map((item, index) => {
        item.type = item.fileExt
        item.newTime = timeFormat(item.uploadTime)
        item.name = item.fileName
        item.imgType = this.getImgType(item.name)
        this.$set(item, 'fileTypeSrc', this.getType2Image(item.imgType))
        this.$set(this.fileTypeList, index, this.getType2Image(item.imgType))
        this.uploadFiles.push(item)
      })
    }
    this.fileChunk = {
      action: prefixPath + 'zuul/api-file/workbench/file/temp/chunk/vue',
      headers: {
        'X-XSRF-TOKEN': sessionStorage.getItem('token'),
        'Authorization': `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      minSize: MINSIZE,
      maxActive: 3,
      maxRetries: 5,
      startBody: {
        override: true,
        path: '/cw'
      },
      uploadBody: {
        override: true,
        path: '/cw'
      },
      finishBody: {
        override: true,
        path: '/cw'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import 'variable/variable.scss';
  .iv-import-wrapper {
    width: 100%;
    margin-top: 16px;
    &.iv-reset {
      padding: 0;
      margin: 0;
      clear: both;
    }
    .iv-importDown-bottom {
      border: 1px dashed rgba(0, 0, 0, .15);
      .iv-importDown-list {
        height: 92px;
        clear: both;
        &:hover {
          background: rgba(240, 250, 255, 1);
        }
      }
      .iv-ins-upload {
        width: 100%;
        height: 100%;
      }
      .iv-ins-upload-firstP {
        width: 64px;
        height: 100%;
        position: relative;
        .iv-iconfont {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -16px;
          font-size: 48px;
        }
      }
    }
  }
</style>
