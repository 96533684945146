<template>
  <div>
    <div v-if="!stepCode">
      <FormLayout
      class="iv-m-t-20"
      ref="ComputedFormLayout"
      :rules="rules"
      :isShowSetting="false"
      :list="formList"
      :model="formItem"
      :readOnly="active == 'see'"
      @on-upload-success="uploadSuccess(...arguments)"
      @on-download-template="downTemp"
      >
    </FormLayout>
    <DragTable
      class="iv-m-t-24"
      noSearch
      :showEdit="false"
      :showDelete="false"
      :showSelect="false"
      :showPage="false"
      ref="DragTable" 
      :immediateForce="true" 
      :columns="columns" 
      :tableData="pmdInspectUsers">
    </DragTable>
    </div>
    <div class="iv-pseronal iv-p-t-40 iv-p-b-40" v-else>
      <div class="iv-main-width iv-p-b-40">
        <LayoutTitle title="上传培养教育意见">
          <FormLayout
            class="iv-m-t-20"
            ref="ComputedFormLayout"
            :rules="rules"
            :isShowSetting="false"
            :list="formList"
            :model="formItem"
            :readOnly="active == 'see'"
            @on-upload-success="uploadSuccess(...arguments)"
            @on-download-template="downTemp"
            >
          </FormLayout>
          <DragTable
            class="iv-m-t-24"
            noSearch
            :showEdit="false"
            :showDelete="false"
            :showSelect="false"
            :showPage="false"
            ref="DragTable" 
            :immediateForce="true" 
            :columns="columns" 
            :tableData="pmdInspectUsers">
          </DragTable>
        </LayoutTitle>
      </div>
      <FooterOperator>
        <div class="iv-text-center">
          <Button type="save" @click="onBtnEvents" v-if="!businessId">保存</Button>
          <Button type="back" @click="close" 
        class="iv-m-l-8 uit-cancel">返回</Button>
        </div>
      </FooterOperator>
    </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import FooterOperator from '@/components/footer-operator'
import generateList from '../sixth.js'
import twentyFirstGenerateList from '../twenty-first.js'
import validate from '@/mixins/validate'
import { timeFormat } from '@/utils'
// import { prefixPath } from '@/config'
import DragTable from '@/components/drag-table-inline'
import Schema from 'async-validator'
export default {
  name: 'addInspectUsers',
  mixins: [validate],
  data () {
    return {
      formItem: {
        createUserName: sessionStorage.getItem('userName'),
        uploadTime: timeFormat(new Date()),
        pmdUserName: '',
        pmdUserCode: '',
        //quarter: '',
        //作为单独页面个人中心，会限制死季度和年限
        year: this.$route.query.year,
        quarter: this.$route.query.quarter || '',
        cf3: '',
        cf4: ''
      },
      businessId: '',
      type: '',
      tableRules: {},
      active: this.$route.query.active,
      pmdUserType: this.$route.query.pmdUserType,
      formId: '',
      title: '上传培养教育意见',
      version: '',
      formCode: '',
      columns: [],
      formList: [],
      pmdInspectUsers: [],
      stepCode: this.$route.query.stepCode,
      uploadFileList: {}
    }
  },
  created () {
    this.rules = {}
    this.orgId = this.$route.query.orgId || sessionStorage.getItem('listOrgId')
    if (this.stepCode) {
      this.businessId = this.$route.query.businessId
      this.type = this.$route.query.type
      this.pmdUserId = this.$route.query.pmdUserId
      this.formCode = this.pmdUserType == 10 || this.pmdUserType == 20 ? 'FZDY0603' : 'FZDY2103'
      this.getBisFileList()
      this.getPmdUser()
      this.getPmdInspectUser()
      this.getDynamicFormInfo()
    } else {
      //模态框的步骤
      this.open(this.params)
    }
  },
  provide () {
    return {
      expandParent: this.expandParent
    }
  },
  props: ['params'],
  watch: {
    'params': {
      handler (val) {
        if (val.pmdUserType) {
          this.open(val)
        }
      },
      //immediate: true,
      deep: true
    }
  },
  methods: {
    open (params) {
      this.businessId = params.businessId
      this.type = params.type
      this.active = params.active
      this.pmdUserId = params.pmdUserId
      this.pmdUserType = params.pmdUserType
      this.formId = params.formId
      this.version = params.version
      this.formCode = this.pmdUserType == 10 || this.pmdUserType == 20 ? 'FZDY0603' : 'FZDY2103'
      // this.$methods('Modal', 'open')
      // this.showModal = true
      this.getBisFileList()
      this.getPmdUser()
      this.getDynamicFormInfo()
    },
    getPmdUser () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getPmdUser',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(({data}) => {
        this.formItem.year = new Date()
        this.formItem.cf3 = data.data.userName
        this.formItem.cf4 = data.data.userCode
        this.getPmdInspectUser(this.formCode == 'FZDY0603' ? data.data.activistDate : data.data.joinPartyDate)
      })
    },
    // 附件模板
    downTemp (type, data) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    // 获取动态表单接口
    getDynamicFormInfo () {
      this.$ajax({
        url: 'api-uum/module/dynamic/form/getLastedFormStructure',
        method: 'GET',
        data: {
          domainCode: 'PMD',
          formCode: this.formCode
        }
      }).then(async ({ data }) => {
        let groupList = data.data.formGroupList || []
        await groupList.map(async (item, index) => {
          if (item.isEnabled == 1 && item.groupCode == 'page03') {
            //判断是不是待办进入，给year和quarter只读
            if(this.stepCode){
              item.formPropertyList.forEach(ite => {
                if ( ite.displayName === '季度'){
                  ite.isReadonly = 1
                }
                if ( ite.displayName === '年份'){
                  ite.isReadonly = 1
                }
              })
            }
            let list = await this.generatePage(item)
            list.map(columnItem => {
              if (columnItem.key == 'year' || columnItem.key == 'quarter' || columnItem.key == 'cf3' || columnItem.key == 'cf4') {
                this.formList.push(columnItem)
              } else {
                this.columns.push(columnItem)
              }
            })
          }
        })
        if (this.businessId) {
          this.$nextTick(() => {
            this.getDetail()
          })
        }
      })
    },
    getBisFileList () {
      this.$ajax({
        url: this.formCode == 'FZDY0603' ? 'api-uum/module/pmd/person/getPmd06' : 'api-uum/module/pmd/person/getPmd21',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId,
          orgId: sessionStorage.getItem('listOrgId')
        }
      }).then(({data}) => {
        this.uploadFileList = data.data.bisFileList || {}
      })
    },
    getPmdInspectUser (applyTime) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/inspect/getPmdInspectUser',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId,
          pmdUserType: this.pmdUserType
        }
      }).then(({data}) => {
        this.pmdInspectUsers = []
        let fileId = this.formCode == 'FZDY0603' ? '02FZDY0604' : '05FZDY2103'
        data.data.map(item => {
          this.pmdInspectUsers.push({
            ...item,
            inspectTime: '',
            redactTime: '',
            opinion: '',
            [fileId]: [],
            applyTime: applyTime
          })
        })
      })
    },
    // 获取详情
    getDetail () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/inspect/getPmdReport',
        method: 'GET',
        data: {
          type: this.type,
          businessId: this.businessId
        }
      }).then(({data}) => {
        let pmdInspectUsersMap = {}
        data.data.pmdInspectUsers && data.data.pmdInspectUsers.map(item => {
          this.pmdUserId = item.pmdUserId
          this.formItem.quarter = item.quarter + ''
          this.formItem.year = new Date(item.year + '')
          pmdInspectUsersMap[item.trainId] = item
        })
        let key = this.formCode == 'FZDY0603' ? 'trainId' : 'introducerId'
        let fileId = this.formCode == 'FZDY0603' ? '02FZDY0604' : '05FZDY2103'
        this.pmdInspectUsers.map(item => {
          item.inspectTime = timeFormat(pmdInspectUsersMap[item[key]].inspectTime)
          item.redactTime = timeFormat(pmdInspectUsersMap[item[key]].redactTime)
          item.opinion = pmdInspectUsersMap[item[key]].opinion
          item.fileId = pmdInspectUsersMap[item[key]].fileId
          item.fileName = pmdInspectUsersMap[item[key]].fileName
          item.inspectUserId = pmdInspectUsersMap[item[key]].inspectUserId
          item[fileId] = [{
            fileId: pmdInspectUsersMap[item[key]].fileId,
            fileName: pmdInspectUsersMap[item[key]].fileName
          }]
          item.cf4 = item.userName
          item.cf3 = item.userCode
        })
      })
    },
    // 列表校验
    validateTable (groupCode, validateList) {
      const list = []
      return new Promise((resolve, reject) => {
        validateList.map((items, index) => {
          let validator = new Schema(this.tableRules[groupCode])
          validator.validate(items, (errors, fields) => {
            // 初始化
            items.errorQueue && (items.errorQueue = null)
            if (!errors) {
              resolve(list)
              return
            }
            errors.forEach(item => {
              this.$set(items, 'errorQueue', Object.assign(items.errorQueue || {}, {
                [item.field]: true
              }))
              list.push(item)
            })
            reject(list)
          })
        })
      })
    },
    // 按钮
    onBtnEvents () {
      this.$refs.ComputedFormLayout.validateify().then(res => {
        if (!res) return
        if (this.pmdInspectUsers.length == 0) {
          return this.$Message.error(this.formCode == 'FZDY0603' ? '还未维护培养联系人,不能维护培养教育意见！' : '还未维护入党介绍人,不能维护培养教育意见')
        }
        let users = []
        this.pmdInspectUsers.map(item => {
          users.push({
            inspectTime: item.inspectTime,
            redactTime: item.redactTime,
            opinion: item.opinion,
            trainId: this.formCode == 'FZDY0603' ? item.trainId : item.introducerId,
            fileId: item.fileId,
            fileName: item.fileName,
            inspectUserId: item.inspectUserId
          })
        })
        this.validateTable('page03', this.pmdInspectUsers).then((list) => {
          if (list.length > 0) {
            return this.$Message.error(list[0].message ? list[0].message : '请完善培养联系人列表！')
          }
          this.$ajax({
            url: this.businessId ? 'api-uum/module/pmd/person/inspect/updPmdReport' : 'api-uum/module/pmd/person/inspect/addPmdInspectUsers',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            disabledData: true,
            data: {
              orgId: sessionStorage.getItem('orgId'),
              quarter: this.formItem.quarter,
              year: timeFormat(this.formItem.year, 'yyyy'),
              pmdUserId: this.pmdUserId,
              pmdInspectUsers: users,
              pmdUserType: this.pmdUserType,
              type: this.type,
              uploadFileList: this.uploadFileList
            }
          }).then(({ data }) => {
            this.formItem = {}
            this.pmdInspectUsers = []
            this.formList = []
            this.columns = []
            this.$Message.success('上传成功')
            // this.$methods('Modal', 'close')
            if (!this.stepCode) {
              this.$emit('on-refresh')
            }
            else{
              this.close()
            }
          })
        }).catch((errors) => {
          if (errors.length) {
            this.$Message.error(errors[0].message ? errors[0].message : '请完善培养联系人列表！')
          }
        })
      })
    },
    // 生成表单和表格
    generatePage (index) {
      const list = this.formCode == 'FZDY0603' ? generateList.call(this, index) : twentyFirstGenerateList.call(this, index)
      list.forEach(item => {
        if (item.type === 'InputNumber' || item.type === 'Number') {
          this.$set(this.formItem, item[$field], null)
        }
      })
      return list
    },
    changeTableData (groupCode, tableIndex, index, propertyCode, value) {
      this.pmdInspectUsers[index][propertyCode] = value
    },
    close () {
      //this.$router.go(-1)
      this.$router.push({
        path: 'index'
      })
    },
    // 表单附件上传
    uploadSuccess (file, key, index, code) {
      if (file.success) {
        this.formItem['fileId'] = file.response.data.fileId
        this.formItem['fileName'] = file.response.data.fileName
        if (this.formCode == 'FZDY0603') {
          this.formItem['02FZDY0602'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        } else {
          this.formItem['05FZDY2101'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        }
        this.$Message.success('上传成功')
      }
    }
  },
  components: {
    FormLayout,
    DragTable,
    LayoutTitle,
    FooterOperator
  }
}
</script>
<style lang="scss" scoped>
.iv-pseronal {
  min-height: 100%;;
  background-color: #fff;
}
</style>
