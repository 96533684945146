export const chunkUploadMixin = {
  props: {
    saveToServer: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    pollingCheckComposed (newFile) {
      // 间隔2s轮询一次
      setTimeout(() => {
        this.$ajax({
          url: 'zuul/api-file/workbench/file/temp/chunk/vue',
          method: 'POST',
          data: {
            override: true,
            path: '/cw',
            phase: 'finish',
            session_id: newFile.chunk.sessionId
          },
          disabledCSRF: true,
          contentType: 'application/json'
        })
          .then(({data}) => {
            // 如果有data属性，说明合成完成
            if (data && data.data) {
              newFile.response.data = data.data
              const path = data.data.prefix
              const name = data.data.name
              if (!this.saveToServer) {
                return this.uploadSuccess(newFile)
              }
              this.$ajax({
                url: 'zuul/api-file/workbench/file/temp/saveToServer',
                method: 'POST',
                data: {
                  filePath: `/${path}/${name}`,
                  isDeleteOrigin: false,
                  ...this.uploadData
                }
              }).then(({data}) => {
                return this.uploadSuccess(newFile)
              }).catch(() => {
                this.$Message.error('上传文件失败，请重试')
              })
              return
            }
            // 否则 递归查询
            this.pollingCheckComposed(newFile)
          })
          .catch(() => {
            //上传失败时
            this.uploadError(newFile)
          })
      }, 2000)
    },
    checkChunkUpload (newFile) {
      // 如果文件 小于 切片上传配置的大小 则为正常上传
      newFile.size = newFile.size || 0
      if (newFile.size < this.fileChunk.minSize) {
        return this.uploadSuccess(newFile)
      }
      // 如果saveToServer为false，则表示不需要轮询
      // 后台返回status为success 并且有data属性
      // 代表文件合成成功
      if (newFile.response.status == 'success' && newFile.response.data) {
        return this.uploadSuccess(newFile)
      }
      // 否则 轮询查询合成状态
      this.pollingCheckComposed(newFile)
    }
  }
}
