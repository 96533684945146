/*
*anthor:liuwei
*createDate:2021/02/04
*updateDate:2021/03/24
*/
<template>
  <div class="iv-universal-tree">
    <!-- 页面全局禁用 -->
    <!-- <Input :value="checkVal" v-if="disabled && isFromComponent" disabled></Input> -->
    <!-- 带选择的input -->
    <Input readonly :value="checkVal" v-if="isFromComponent" :disabled="disabled">
      <span slot="append" v-show="!disabled" @click="onShowTree" class="iv-pointer">选择</span>
    </Input>
    <!-- 弹框区域 -->
    <AuthParty
      ref="authParty"
      v-if="isAccurate"
      :listData="treeParamsMap"
      :listUrl="treeUrl"
      :title="props.modalTitle || '通用树'"
      :listType ="listType || 'all'"
      :listCustomData="treeCustomData"
      @on-process-data="confirmOk"
      :defaultValue="value"
    ></AuthParty>
    <Modal ref="universalModal" :title="props.modalTitle || '通用树'" @on-ok="confirm" size="small">
      <ModalLayout>
        <div v-if="showTree">
          <div v-html="topTitle" v-if="topTitle"></div>
          <h3 v-if="isSingle" class="iv-fs-14 iv-symbol-mb16">{{props.tipsTitle || '当前选择的组织：'}}{{title}}</h3>
          <!-- 党组织奖惩 -->
          <LazyTree
            ref="tree"
            :async="isAsync"
            :treeType="treeType"
            :treeUrl="treeUrl"
            :changeTree="false"
            :showSearch="showSearch"
            :showCheckbox="!isSingle"
            :checkStrictly="checkStrictly"
            :treeParams="treeParamsMap"
            :treeCustomData="treeCustomData"
            :treeMap="treeMap"
            :isRootDisabled="isRootDisabled"
            @on-check-change="onCheckChange"
            @on-select-change="onSelectChangeTree"
            :treeStyle="{'overflow-y': 'scroll', 'max-height': '355px', 'height': '355px;'}">
          </LazyTree>
        </div>
        <div class="iv-p-20 iv-fs-16" v-else>暂无数据</div>
        <!-- <IconTree
          v-else
          ref="tree"
          :data="dataTree"
          :showSpread="false"
          :reloadTable="false"
          :showIcon="false"
          @on-select-change="onSelectChangeTree"/> -->
      </ModalLayout>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/modal'
import IconTree from '@/components/icon-tree'
import tagGroup from '@/components/tag-group'
import LazyTree from '@/components/lazy-tree'
import ModalLayout from '@/components/modal/layout'
import AuthParty from './auth/party'

export default {
  name: 'universalTree',
  props: {
    value: {
      type: [String, Array],
      default () {
        return ''
      }
    },
    isSingle: { //单选树和多选树
      type: Boolean,
      default: true
    },
    showSearch: { //是否显示搜索框
      type: Boolean,
      default: true
    },
    checkStrictly: { //多选树  是否开启父子节点不关联
      type: Boolean,
      default: false
    },
    disabled: { //computed-form-layout组件是否禁用input
      type: Boolean,
      default: false
    },
    props: { //接收的参数
      type: Object,
      default () {
        return {
          modalTitle: '通用树1',
          tipsTitle: '当前选择的组织：'
        }
      }
    },
    topTitle: { //顶部的提示  支持自定义内容  v-html传入
      type: String,
      default: ''
    },
    isFromComponent: { //是否computed-form-layout使用
      type: Boolean,
      default: true
    },
    isAsync: { //异步树
      type: Boolean,
      default: true
    },
    treeCustomData: { //自定义tree封装的函数
      type: Object,
      default () {
        return {}
      }
    },
    treeParams: { //传递的树参数
      type: Object,
      default () {
        return {}
      }
    },
    treeMap: { //map 树
      type: Object,
      default () {
        return {
          key: 'orgId'
        }
      }
    },
    treeType: {
      type: Object,
      default () {
        return {
          type: 'single-all'
        }
      }
    },
    map: { //多选反显时的map
      type: Object,
      default () {
        return {
          name: 'userName'
        }
      }
    },
    treeUrl: { //同步树
      type: String,
      default: ''
    },
    keyValue: { //computedLayoutFull中的表单的键
      type: String,
      default: ''
    },
    listType: { //精确查询类型
      type: String,
      default: 'all'
    },
    isRootDisabled: { //根组织是否不可选
      type: Boolean,
      default: false
    },
    isRootOrgConfig: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkVal: '',
      title: '', //单选的组织名
      treeParamsMap: {},
      curOrgazation: [],
      dataTree: [], //同步树数据
      isAccurate: false,
      bizLineCode: '',
      orgId: '',
      showTree: true
    }
  },
  created () {
    this.setParamsId()
    this.initTreeParams()
    this.isAccurate = this.isAccurateFun()
    if (!this.isAsync) {
      //this.getTreeData()
    }
  },
  watch: {
    value: {
      handler (val) {
        if (Array.isArray(val)) {
          this.checkVal = val.map(item => item[this.map.name]).join(',')
        } else {
          this.checkVal = val
        }
      },
      immediate: true
    },
    treeParams: {
      handler () {
        this.initTreeParams()
      },
      deep: true
    }
  },
  methods: {
    //根据业务线设置id
    setParamsId (bizLineCode) {
      const idMap = {
        'YWX01': 'orgId',
        'YWX03': 'fullId',
        'YWX04': 'legId',
        'YWX06': 'planId',
        'YWX05': 'cultureId'
      }
      this.bizLineCode = bizLineCode || this.$decode(this.$route.query.bizLineCode || this.$encode('YWX01'))
      this.orgId = this.$decode(sessionStorage.getItem(idMap[this.bizLineCode]) || '')
      if (this.treeParams.isExistOrg === false) {
        this.orgId = -1
      }
      if (!this.orgId) {
        this.showTree = false
      }
    },
    //初始化树的参数
    initTreeParams () {
      if (this.treeParams.bizLineCode) {
        this.setParamsId(this.treeParams.bizLineCode)
      }
      this.treeParamsMap = Object.assign({
        scope: 2,
        orgId: this.orgId
      }, this.treeParams)
    },
    getTreeData () {
      this.$ajax({
        url: this.url,
        method: 'get',
        data: Object.assign(this.props.searchTree, this.treeParams)
      }).then(({data}) => {
        data.data[0].expand = true
        this.dataTree = data.data
        //this.rootId = cloneDeep(data.data[0].id)
      })
    },
    //树选择  单选
    onSelectChangeTree (val) {
      if (!this.isSingle || val[0].disabled) return
      this.title = val[0].title
      this.curOrgazation = val
    },
    //树选择  多选
    onCheckChange (val) {
      this.title = val.map(item => item.title).join(',')
      this.curOrgazation = val
    },
    // 判断是否全树并且是精确查找
    isAccurateFun () {
      let isRootId = this.isSingle && this.isRootOrgConfig
      // let isRootId = this.isSingle && (this.treeParamsMap.orgId && this.treeParamsMap.orgId == '-1')
      return isRootId
    },
    //computed-form-layout 点击选择显示弹框
    onShowTree () {
      this.isAccurate = this.isAccurateFun()
      if (this.isAccurate) {
        this.$ajax({
          url: 'module/config/getInfoSafe',
          method: 'GET',
          data: {
            type: 'ORG'
          }
        }).then(({data}) => {
          // ACCURATE TREE
          if (data.data.infoSafeModel === 'ACCURATE') {
            this.$nextTick(() => {
              this.$methods('authParty', 'onShow')
            })
            return
          }
          this.isAccurate = false
          this.openModal()
        })
        return
      }
      this.openModal()
    },
    //弹框确认
    confirm () {
      if (this.treeParams.selectId && !this.curOrgazation.length) {
        this.curOrgazation = this.$methods('tree', 'getCheckedNodes')
      }
      this.confirmOk(this.curOrgazation)
      this.hiddenModal()
    },
    confirmOk (data, type) {
      if (type && type == 'table') {
        this.title = data[0].title
      }
      this.$emit('input', this.title)
      this.$emit('on-change', data)
      this.$emit('on-change-tree', data, this.keyValue)
    },
    //打开弹框
    openModal () {
      this.$methods('universalModal', 'open')
    },
    //隐藏弹框
    hiddenModal () {
      this.$methods('universalModal', 'close')
    }
  },
  components: {
    Modal,
    tagGroup,
    LazyTree,
    IconTree,
    ModalLayout,
    AuthParty
  }
}
</script>
