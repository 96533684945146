// import { prefixPath } from '@/config'
import { timeFormat } from '@/utils'
// import operate from '../operate/sixth-operate'
import ButtonFile from '@/components/button-upload'

export default function (config) {
  if (!config) return []
  let _this = this
  _this.tableRules[config.groupCode] = {}
  const list = config.formPropertyList.map(item => {
    let props = item.propertyConfig || '{}'
    if (props.indexOf('session_listOrgId') > -1) {
      props = props.replace('session_listOrgId', sessionStorage.getItem('listOrgId'))
    }
    if (props.indexOf('prefixPath') > -1) {
      props = props.replace('prefixPath', 'api-uum')
    }
    if (props.indexOf('orgCategoryCode') > -1) {
      let orgCategoryCode = sessionStorage.getItem('orgCategoryCode')
      props = props.replace('orgCategoryCode', orgCategoryCode == 1 ? 61 : orgCategoryCode)
    }
    let objProps = JSON.parse(props) || {}
    if (item.propertyCode == 'year' || item.propertyCode == 'quarter' || item.propertyCode == 'cf3' || item.propertyCode == 'cf4' || config.groupType == 1) { // 表单
      if (item.widgetType == 'hidden') {
        item.props = objProps
        _this.hiddenFile[item.propertyCode] = item
      }
      if (item.widgetType == 'fileUpload') { // 附件信息
        objProps.data.bizId = _this.logId || _this.$route.query.logId
        objProps.isShowFileList = true
      }
      if (item.widgetType == 'DatePicker') {
        objProps.option = item.option
      }
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        type: item.widgetType,
        props: objProps,
        colSpan: item.colspan || '8',
        rules: item.isNotNull == 1 ? [{'required': true, message: '此项必填'}] : [],
        readOnly: item.isReadonly == 1,
        id: item.propertyId,
        propertyName: item.propertyName,
        isEnabled: item.isEnabled,
        itemDescribe: item.description,
        format: objProps.format || '',
        optionList: objProps.optionList || [],
        ref: item.propertyCode
      } : null
    } else { // 表格
      if (item.isNotNull == 1 && config.groupCode != 'bisFileList') {
        _this.tableRules[config.groupCode][item.propertyCode] = [{
          required: true,
          message: item.displayName + '必填'
        }]
      }
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        nowType: config.groupName,
        showType: item.widgetType || '',
        renderHeader: item.isNotNull == 1 ? (h, {row}) => {
          return (<div>
            <span style="marginRight: '2px';color: red">*</span>
            <span>{item.displayName || item.propertyName}</span>
          </div>)
        } : null,
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || '',
        props: objProps,
        render: (h, {row}) => {
          if (item.widgetType == 'DatePicker') {
            if ((item.propertyCode == 'inspectTime' || item.propertyCode == 'redactTime') && _this.active != 'see') {
              return h('datePicker', {
                props: {
                  value: timeFormat(row[item.propertyCode]),
                  options: {
                    disabledDate (date) {
                      return date && date.valueOf() < new Date(row.applyTime)
                    }
                  }
                },
                style: 'width: 100%;',
                class: row?.errorQueue?.leaveTime == true ? 'red' : '',
                on: {
                  'on-change': (e) => {
                    row[item.propertyCode] = e || ''
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, item.propertyCode, row[item.propertyCode])
                  }
                }
              })
            } else {
              return h('span', timeFormat(row[item.propertyCode]))
            }
          } else if (item.propertyCode == 'opinion') {
            if (_this.active != 'see') {
              return h('Input', {
                props: {
                  value: row[item.propertyCode],
                  type: 'textarea'
                },
                style: 'width: 100%;',
                on: {
                  'on-blur': (e) => {
                    row[item.propertyCode] = e.currentTarget.value || ''
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, item.propertyCode, row[item.propertyCode])
                  }
                }
              })
            } else {
              return h('span', row[item.propertyCode])
            }
          } else if (item.propertyCode == '05FZDY2103') {
            if (_this.active != 'see') {
              let edit = []
              edit = [h(ButtonFile, {
                style: '',
                class: 'iv-m-t-12',
                props: {
                  accept: objProps.accept || '.pdf,.jpg,.jpeg,.png,.gif',
                  label: '文件上传',
                  isText: true,
                  url: 'api-uum/file/upload',
                  dynamicClass: 'iv-main-color',
                  data: {
                    override: true,
                    path: '/cw',
                    groupId: ''
                  },
                  isShowFileList: true,
                  getUploadFiles: row[item.propertyCode]
                },
                on: {
                  'on-upload-success': (file) => {
                    let currentFile = file.response.data
                    if (!_this.uploadFileList[item.propertyCode]) {
                      _this.uploadFileList[item.propertyCode] = [{
                        fileName: currentFile.fileName,
                        fileId: currentFile.fileId
                      }]
                    } else if (!row[item.propertyCode][0]) {
                      _this.uploadFileList[item.propertyCode].push({
                        fileName: currentFile.fileName,
                        fileId: currentFile.fileId
                      })
                    } else {
                      let _index = _this.uploadFileList[item.propertyCode].findIndex(_ => { return _.fileId == row[item.propertyCode][0].fileId })
                      if (_index > -1) {
                        _this.uploadFileList[item.propertyCode][_index] = {
                          fileName: currentFile.fileName,
                          fileId: currentFile.fileId
                        }
                      } else {
                        _this.uploadFileList[item.propertyCode].push({
                          fileName: currentFile.fileName,
                          fileId: currentFile.fileId
                        })
                      }
                    }
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, 'fileId', currentFile.fileId)
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, 'fileName', currentFile.fileName)
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, item.propertyCode, [{
                      fileName: currentFile.fileName,
                      fileId: currentFile.fileId
                    }])
                    this.$Message.success('上传成功')
                  },
                  'on-input-clear': (com, file) => {
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, 'fileId', '')
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, 'fileName', '')
                    _this.changeTableData(config.groupCode, row._tableIndex, row._index, item.propertyCode, [])
                    _this.uploadFileList[item.propertyCode] && _this.uploadFileList[item.propertyCode].map((_, index) => {
                      if (file.fileId == _.fileId) {
                        _this.uploadFileList[item.propertyCode].splice(index, 1)
                      }
                    })
                    this.$Message.success('删除成功')
                  }
                }
              })]
              if (objProps.downloadType == 1) {
                edit.push(h('span', {
                  class: 'iv-pointer iv-hover-color iv-main-color',
                  style: 'position: absolute;bottom: 6px;left: 70px;',
                  on: {
                    click: () => {
                      _this.downTemp('download', {attachFileConfigId: objProps.data.attachFileConfigId, changeUserId: row.changeUserId})
                    }
                  }
                }, '下载模板'))
              }
              return h('div', {
                style: 'position: relative;'
              }, edit)
            } else {
              let fileId, fileName
              if (row.fileId) {
                fileId = row['fileId']
                fileName = row['fileName']
              }
              return h('span', {
                style: {
                  'padding': '0',
                  'line-height': '20px',
                  'display': 'inline-block',
                  'cursor': 'pointer'
                },
                class: ['iv-main-color', 'iv-hover-color'],
                on: {
                  'click': () => {
                    // let fileId = this.uploadFileList['02FZDY0604'].fileId
                    let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
                    window.open(url)
                  }
                }
              }, fileName)
            }
          } else {
            return h('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  // if (list[0].nowType == '附件') {
  //   list.push({
  //     title: '操作',
  //     [$field]: 'operate',
  //     width: list[0].nowType == '附件' ? 200 : 100,
  //     isOperate: true,
  //     slotComponent: operate
  //   })
  // }
  return config.isEnabled == 1 ? list : []
}
