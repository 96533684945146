<template>
  <div class="iv-checkbox-multiple">
    <CheckboxGroup
      v-model="realValue"
      style="width: 100%"
      :disabled="disabled"
      @on-change="onChangeCheckboxGroup">
      <Checkbox
        :key="$$$index"
        :disabled="disabled"
        :label="opt.value"
        v-for="(opt, $$$index) in optionList">
        {{opt.label}}
      </Checkbox>
    </CheckboxGroup>
    <div v-show="realValue.includes('2')" class="iv-checkbox-multiple-wrap iv-m-t-8">
      <CheckboxGroup v-model="childSelect" @on-change="childrenCheckboxChange">
        <Checkbox
          :key="$$$index"
          :disabled="disabled"
          :label="opt.value"
          v-for="(opt, $$$index) in childOptionList">
          {{opt.label}}
        </Checkbox>
      </CheckboxGroup>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    childVal: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: ''
    },
    optionList: {
      type: Array,
      default () {
        return []
      }
    },
    childOptionList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      realValue: [],
      childSelect: []
    }
  },
  created () {
    this.realValue = this.value
    this.childSelect = this.childVal
  },
  watch: {
    value () {
      this.realValue = this.value
    },
    childVal () {
      this.childSelect = this.childVal
    }
  },
  methods: {
    onChangeCheckboxGroup (val) {
      this.$emit('input', val)
    },
    //子节点复选框选中
    childrenCheckboxChange (val) {
      this.$emit('on-child-select', val, this.field)
    }
  }
}
</script>

<style lang="scss" scoped>
.iv-checkbox-multiple {
  width: 100%;
  margin-top: 24px;
  .ivu-checkbox-group {
    position: static;
  }
  .iv-checkbox-multiple-wrap {
    border-radius: 8px;
    background-color: #F9F8F8;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}
</style>
