<!--管理用户管理-->
<template>
  <div>
    <Modal
      loading
      transfer
      @on-ok="onOK"
      @on-cancel="hidedenModal"
      ref="Modal"
      :title="title"
      >
      <Layout>
        <h4 class="iv-m-b-16">
          精确查找党组织信息
        </h4>
        <SearchLayout
          isNest
          :spreadType="2"
          :list="searchList"
          @on-search="onSearch"
          searchBtText="查询" 
        />
        <Table
          v-show="showTable"
          ref="Table"
          :noSearch="noSearch"
          autoHeight
          :showTree="false"
          :showEdit="false"
          :showDelete="false"
          :showSetButton="false"
          :showSelect="showSelect"
          :showPage="showPage"
          @on-list="onList"
          @on-radio-change="onRadioChange"
          :showRadioSelect="showRadioSelect"
          :menuName="menuName"
          :listData="listDataTable"
          :url="listEditUrl"
          :getAction="getAction"
          :columns="columns">
        </Table>
      </Layout>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/modal'
import Table from '@/components/drag-table-inline'
import Layout from '@/components/modal/layout'
import columnsParty from './columnsParty'
import SearchLayout from '@/components/search-layout'
import tagGroup from '@/components/tag-group'
export default {
  props: {
    getAction: {
      type: Function
    },
    listData: {
      type: Object,
      default () {
        return {}
      }
    },
    listUrl: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: [String, Array],
      default () {
        return ''
      }
    },
    customColumns: {
      type: Array,
      default () {
        return []
      }
    },
    showPage: {
      type: Boolean,
      default: true
    },
    noSearch: {
      type: Boolean,
      default: true
    },
    showRadioSelect: { //单选
      type: Boolean,
      default: true
    },
    showSelect: { //多选
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default () {
        return {
          value: 'value', // 用于操作的值key名
          title: 'title' // 用于显示的值key名
        }
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    menuName: {
      type: String,
      default: 'selectPartyDefaultCode'
    },
    title: {
      type: String,
      default: '选组织'
    },
    listType: {
      type: String,
      default: 'all'
    },
    listCustomData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      listDataTable: {},
      columns: [],
      onListData: [], // 所有数据
      onCurrentListData: [], // 当前页数据
      value: [],
      list: [],
      searchList: [],
      selectObj: {},
      listEditUrl: 'precisequery/preciseQueryOrg', // 列表url
      showTable: false,
      listUrlMap: {
        'relation': 'module/transfer/target/tree/preciseQueryOrg',
        'partymemberfloatlog': 'module/partymemberfloatlog/tree/preciseQueryOrg',
        'branchChange': 'pmd/orgchange/tree/preciseQueryOrg'
      }
    }
  },
  components: {
    Table,
    Modal,
    Layout,
    SearchLayout,
    tagGroup
  },
  created () {
    this.initDefaultValue(this.defaultValue)
    this.setDefaultTable()
  },
  watch: {
    defaultValue: {
      handler (val) {
        this.initDefaultValue(val)
      },
      deep: true,
      immediate: true
    }
  },
  inject: {
    expandParent: {
      default () {
        return function () {}
      }
    }
  },
  methods: {
    // 转换请求数据
    switchCustomParams (type) {
      if (this.listCustomData && Object.keys(this.listCustomData).length != 0) {
        if (type) {
          return this.setArrayUrl(this.listCustomData.getListParamsAuto())
        }
        return this.setArrayUrl(this.listCustomData.getListParamsAutoOther())
      }
    },
    // 数组转换
    setArrayUrl (obj) {
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          let str = ''
          obj[key].forEach((item, inx) => {
            if (inx === (obj[key].length - 1)) {
              str += item
            } else {
              str += item + '&' + key + '='
            }
          })
          str = str.substr(0, str.length - 1)
          obj[key] = str
        }
      }
      return obj
    },
    // 初始化table
    setDefaultTable () {
      this.listEditUrl = this.listUrlMap[this.listType] || 'precisequery/preciseQueryOrg'
    },
    initDefaultValue (val) {},
    // 获取列表数据
    onList (data) {
      this.onListData = data
    },
    //单选事件
    onRadioChange (list) {
      this.selectObj = Object.assign(list, {
        id: list.orgId,
        title: list.orgName
      })
    },
    // 显示模态框
    onShow () {
      this.initTable()
      this.initDefaultValue(this.defaultValue)
      this.$methods('Modal', 'open')
    },
    //模态框隐藏
    hidedenModal () {
      this.onListData.length = 0
      this.showTable = false
      this.$Modal.remove()
    },
    // 关闭
    onCancel () {
      this.$methods('Modal', 'close')
    },
    // 保存
    onOK () {
      if (!Object.keys(this.selectObj).length) {
        return this.$Message.error('需要选择一个党组织后再确定！')
      }
      this.$emit('on-process-data', [this.selectObj], 'table')
      this.onCancel()
    },
    // 搜索
    onSearch (params, type) {
      if (!type) return
      if (type !== 'reset') {
        if (!params.orgCode) return this.$Message.error('必须通过中组部编码精确查找党组织信息。')
        this.showTable = true
        params.orgId = sessionStorage.getItem('orgId')
        Object.assign(this.listDataTable, params)
        this.$methods('Table', 'forceRender')
        return
      }
      this.showTable = false
      this.initTable()
    },
    // 初始化列表
    initTable () {
      this.onListData.length = 0
      this.listDataTable = this.switchCustomParams()
      this.listEditUrl = this.listUrlMap[this.listType] || 'precisequery/preciseQueryOrg'
      this.columns = this.customColumns.length ? this.customColumns : columnsParty.call(this)
      this.searchList = [
        {
          label: '中组部编码',
          type: 'Input',
          name: 'orgCode',
          placeholder: '请输入中组部编码精确查找'
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .iv-search-container {
  .iv-label {
    margin-top: 4px !important;
  }
}
</style>